//form fields
ui.formFieldGeoLocation = function(){
	var scope = ".js-form-field-geo-location";
	var defaultRadius = 1000;

    function gmReady(){
        $(scope).each(
            function(){
                var $field = $(this);
                var input = $field.find(scope+"__input").get(0);
                var ac = new google.maps.places.Autocomplete(input,$field.data("autocomplete-options"));
                ac.addListener(
                    'place_changed',
                    function () {
                        var place = ac.getPlace();
                        if(place && place.geometry){
                            var value = {};
                            var point = place.geometry.location;
                            if(!point || !point.lat()){
                                point = place.geometry.viewport.getCenter();
                            }
                            value.lat = point.lat();
                            value.lng = point.lng();
                            value.description = place.formatted_address;
                            $field.formFieldSetVal(value);
                        }
                    }
                );
            }
        );
    }

	ui.ready(
		scope,
		function($field){
			var $input = $field.find(scope+"__input");
            var $value = $field.find(scope+"__value");
            var $modal = $field.find(scope+"__modal").clone().appendTo($("body"));
            var $modalMap = $modal.find(scope+"__modal-map");
            var map,mapMarker,mapArea;
            var useRadius = $field.data("use-radius");

            function setValue(value,noEvent){
                $value.val(value?JSON.stringify(value):"");
                $field.toggleClass("has-value",$value.val()?true:false);
                $modal.modal("hide");
                if(value){
                    $input.val(value.description?value.description:value.lat.toFixed(4)+","+value.lng.toFixed(4));
                }else{
                    $input.val("");
                }
                if(!noEvent)
                    $field.trigger("formField:changeVal");
            }

            function updateRadius(radius,setRange){
                radius = parseFloat(radius);
                mapMarker.radiusVal = radius;
                mapArea.setRadius(radius);
                var text = mapMarker.radiusVal+" m";
                if(radius>=1000){
                    text = (radius/1000).toFixed(1)+" km";
                }
                $modal.find(scope+"__modal-radius-distance").html(text);
                if(setRange){
                    $modal.find(scope+"__modal-radius-input").val(radius);
                }
            }

            function openModal(){
                $modal.modal("show");
                var options = $field.data("map-options");
                if(!map){
                    map = new google.maps.Map($modalMap.get(0),options);
                    mapMarker = new google.maps.Marker(
                        {
                            position:map.getCenter(),
                            visible:true,
                            draggable:true
                        }
                    );
                    mapMarker.setMap(map);

                    google.maps.event.addListener(
                        map,
                        "click",
                        function(ev){
                            mapMarker.setPosition(ev.latLng);
                        }
                    );

                    if(useRadius){
                        mapArea = new google.maps.Circle(
                            {
                                center:map.getCenter(),
                                radius:2000,
                                fillColor:"#FF0000",
                                fillOpacity:0.3,
                                strokeColor:"#990000",
                                strokeWidth:2
                            }
                        );
                        mapArea.setMap(map);

                        $modal.find(scope+"__modal-radius-input").on(
                            "change input",
                            function(){
                                updateRadius($(this).val());
                            }
                        );

                        google.maps.event.addListener(
                            mapMarker,
                            "position_changed",
                            function(){
                                mapArea.setCenter(mapMarker.getPosition());
                            }
                        );
                    }

                    $modal.find(scope+"__save").click(
                        function(){
                            $modal.modal("hide");
                            var pos = mapMarker.getPosition();
                            var val = {lat:pos.lat(),lng:pos.lng()};
                            if(mapMarker.radiusVal){
                                val.radius = mapMarker.radiusVal;
                            }

                            var geo = new google.maps.Geocoder;
                            geo.geocode(
                                {'location': mapMarker.getPosition()},
                                function(results, status){
                                    if(status==='OK' && results[0]){
                                        val.description = results[0].formatted_address;
                                    }
                                    setValue(val);
                                }
                            );
                        }
                    );
                }

                var position = map.getCenter();
                if($value.val()){
                    position = JSON.parse($value.val());
                    map.setCenter(position);
                    map.setZoom(10);
                }
                mapMarker.setPosition(position);
                if(useRadius){
                    updateRadius(position.radius?position.radius:defaultRadius,true);
                }
            }

            $field.data(
                "funcGetVal",
                function(){
                    if($value.val())
                        return JSON.parse($value.val());
                    else
                        return null;
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    setValue(val);
                }
            );

            $field.find(scope+"__empty").click(
                function(ev){
                    ev.preventDefault();
                    $input.val("");
                    setValue("");
                }
            );

            $field.find(scope+"__search").click(
                function(ev){
                    ev.preventDefault();
                    openModal();
                }
            );

            $input.keypress(
                function(ev){
                    if(ev.which==13){
                        ev.preventDefault();
                        ev.stopPropagation();
                    }
                }
            );

            if($value.val()){
                setValue(JSON.parse($value.val()),true);
            }

            gvf.loadJsScript("https://maps.googleapis.com/maps/api/js?key="+$field.data("gmkey")+"&callback=ui.formFieldGeoLocation.gmReady&libraries=places");
		}
	);

	return {
        "gmReady":gmReady
    };
}();