//form fields
ui.formFieldInput = function(){
	var scope = ".js-form-field-input";
	var tt;

	ui.ready(
		scope,
		function($field){
            var $check = $field.find("[type=checkbox]");
            var $searchBox = $field.find("[name=q]");
            var isCheck = ($check.length>0);
            var isHidden = $field.is("input");
            $searchBox.addClass("u-input-searching");
            $field.data(
                "funcGetVal",
                function(){
                    if(isHidden)
                        return $field.val();
                    else if(isCheck)
                        return $check.get(0).checked;
                    else
                        return $field.find(":input").val();
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    if(isHidden)
                        $field.val(val);
                    else if(isCheck)
                        $check.get(0).checked = val?true:false;
                    else
                        $field.find(":input").val(val);
                    $field.trigger("formField:changeVal");
                }
            );

            $field.find(":input").on(
                "change",
                function(){
                    $field.trigger("formField:changeVal");
                }
            );

            $field.find(":input").keyup(
                function(ev){
                    tt = gvf.delay(
                        500,
                        function(){
                            $field.trigger("formField:checkRestrictions",{"input":$(this)});
                        }
                    );
                }
            ).blur(
                function(){
                    if(tt){
                        clearTimeout(tt);
                        tt = null;
                    }
                    $field.trigger("formField:checkRestrictions",{"input":$(this)});
                }
            );
		}
	);
}();