var cusOfferLineEditionForm = function(){
    var scope = ".js-cus-offer-line-edition-form";
    ui.ready(scope,function($form){

        $form.find(scope+"__load-request").click(function(ev){
            ev.preventDefault();
            var $request = $(this).closest(scope+"__request");
            gvf.endpoint("controller\\cp\\cusOfferLineController::selectSupplierRequest",[$request.data("request-id")],function(resp){
                if(resp.status=="ok"){
                   /* $form.find("[name=price]").val(resp.price);
                    var unit_price = $form.find("[data-name='sale_price_unit']").formFieldVal();
                    if(unit_price!==""){
                        var margin = 100*((unit_price/resp.price)-1);
                        $form.find("[data-name='margin']").formFieldSetVal(margin.toFixed(2));
                    }else{
                        updateLineForm(true);
                    }*/
                    $request.addClass("table-success");
                }else{
                    ui.alertEndpointResponse(resp);
                }
            });
        });

        $form.find(scope+"__remove-request").click(function(ev){
            ev.preventDefault();
            var $request = $(this).closest(scope+"__request");
            gvf.endpoint("controller\\cp\\cusOfferLineController::unSelectSupplierRequest",[$request.data("request-id")],function(resp){
                if(resp.status=="ok"){
                    $request.removeClass("table-success");
                }else{
                    ui.alertEndpointResponse(resp);
                }
            });
        });


        function renderPreferredSupplier(){
            var supplier_category = $form.find("[data-name='supplier_category_id']");
            var categoryId = $(supplier_category).find("[name='supplier_category_id']").val();
            var $regularSuppliers = $form.find("[data-name='regular_suppliers']");
            var $hiddenPreferredSuppliers = $form.find("[name='preferred_suppliers']");
            var cost_type = $form.find("[name='cost_type']").val();
            var $preferredSuppliers = $form.find(scope+"__preferred-suppliers");
            var labour = 1;
            if(cost_type!=labour && $regularSuppliers.length>0 && $hiddenPreferredSuppliers.length>0){ //mano de obra propia
                gvf.endpoint("controller\\cp\\cusOfferDetailController::getRequiredSuppliersId",[categoryId],function(resp){
                    if(resp.status=="ok"){
                        $hiddenPreferredSuppliers.val(resp.ids);
                        setSupplierContactToNotify(false);
                        setSupplierUsersToNotify(false);
                        gvf.endpoint($regularSuppliers.data("endpoint")+"ModelSelectorInfo",[$regularSuppliers.data("model"),JSON.parse(resp.ids),$regularSuppliers.data("multiple"),false],function(resp){
                            if(resp.status=="ok"){
                                if(resp.html){
                                    $preferredSuppliers.html(resp.html);
                                }else{
                                    $preferredSuppliers.empty();
                                    $preferredSuppliers.html("Sin proveedores preferentes");
                                }
                            }
                        });
                    }
                });
            }
        }

        renderPreferredSupplier();
        $form.find("[data-name='supplier_category_id']").off().on('formField:changeVal',function(event){
            renderPreferredSupplier();
        });

        $form.find("[data-name='departure'],[data-name='subdeparture']").on('formField:changeVal',function(ev){
            var departure = $form.find("[name='departure']").val();
            var subdeparture = $form.find("[name='subdeparture']").val();
            var unitItems = $form.find("[name='unit_items']").val();

            if(unitItems==1){
                if(departure>0 && subdeparture==0){
                    $form.find("[name='cus_offer_detail_unit_id']").val("");
                    $form.find("[data-name='cus_offer_detail_unit_id']").toggleClass("is-required",false);
                    $form.find("[name='cus_offer_detail_unit_id']").prop("disabled",true);
                    $form.find("[data-name='price']").formFieldSetVal("");
                    $form.find("[name='price']").prop("readonly",true);
                    $form.find("[data-name='discount']").formFieldSetVal("");
                    $form.find("[name='discount']").prop("readonly",true);
                    $form.find("[data-name='margin']").formFieldSetVal("");
                    $form.find("[name='margin']").prop("readonly",true);
                }else{
                    $form.find("[name='cus_offer_detail_unit_id']").prop("disabled",false);
                    $form.find("[name='price']").prop("readonly",false);
                    $form.find("[name='discount']").prop("readonly",false);
                    $form.find("[name='margin']").prop("readonly",false);
                }
            }
        });

        $form.find("[name='preferred_suppliers'],[data-name='regular_suppliers']").off().on('formField:changeVal',function(event){
            setSupplierContactToNotify(true);
            setSupplierUsersToNotify(true);
        });

        function setSupplierContactToNotify(changed){
            var initialValue = $form.find("[data-name='cus_contact_supplier']").val();
            if(changed || (!changed && initialValue!="")){
                var suppliers = $form.find("[name='regular_suppliers']").val();
                var preferedsuppliers = $form.find("[name='preferred_suppliers']").val();
                gvf.endpoint("controller\\cp\\cusOfferLineController::getCusContactRelatedToSuppliers",[suppliers,preferedsuppliers],function(resp){
                    if(resp.status=="ok"){
                        if(resp.cus_contact_ids){
                            $form.find("[data-name='cus_contact_supplier']").formFieldSetVal(resp.cus_contact_ids);
                        }else{
                            $form.find("[data-name='cus_contact_supplier']").formFieldSetVal("");
                        }
                        $form.find("[data-name='cus_contact_supplier']").trigger('formField:changeVal');

                    }
                });
            }
        }

        function setSupplierUsersToNotify(changed){
            var initialValue = $form.find("[data-name='cp_user_supplier']").val();
            if(changed || (!changed && initialValue!="")){
                var suppliers = $form.find("[name='regular_suppliers']").val();
                var preferedsuppliers = $form.find("[name='preferred_suppliers']").val();
                gvf.endpoint("controller\\cp\\cusOfferLineController::getCpUserRelatedToSuppliers",[suppliers,preferedsuppliers],function(resp){
                    if(resp.status=="ok"){
                        if(resp.cp_user_supplier){
                            $form.find("[data-name='cp_user_supplier']").formFieldSetVal(resp.cp_user_supplier);
                        }else{
                            $form.find("[data-name='cp_user_supplier']").formFieldSetVal("");
                        }
                        $form.find("[data-name='cp_user_supplier']").trigger('formField:changeVal');

                    }
                });
            }
        }


        $form.find("[name='cost_type']").change(function(event){
            var labour_cost = 1;
            var product_cost = 6;
            var hour_unit = 1;
            var cost_type = $form.find("[name='cost_type']").val();
            if($form.find("[name='cost_type']").val()==labour_cost){
                $form.find("[name='cus_offer_detail_unit_id']").val(hour_unit);
            }
            hideCostTypeReference();
        });


        $form.find("[data-name='labour_reference']").off().on('formField:changeVal',function(event){
            if(!($form.find("[name='cost_type']").val()==1)){
                return;
            }

            var rateId = $form.find("[name='labour_reference']").val();
            if(rateId){
                gvf.endpoint("controller\\cp\\cusOfferDetailController::getPriceByRateId",[rateId],function(resp){
                    if(resp.status=="ok"){
                        setPrice(resp.data.price);
                    }
                });
            }
        });

        $form.find("[data-name='tarifec_reference']").off().on('formField:changeVal',function(event){
            var productId = $form.find("[name='tarifec_reference']").val();
            if(productId){
                gvf.endpoint("controller\\cp\\productRateDetailController::getPriceAndDescription",[productId],function(resp){
                    if(resp.status=="ok"){
                        setOfferLineData(resp.price,resp.description,"tarifec_reference");
                    }
                });
            }
        });

        $form.find("[data-name='material_reference']").off().on('formField:changeVal',function(event){
            var materialId = $form.find("[name='material_reference']").val();
            if(materialId){
                gvf.endpoint("controller\\cp\\cusOfferLineMaterialRateDetailController::getPriceAndDescription",[materialId],function(resp){
                    if(resp.status=="ok"){
                        setOfferLineData(resp.price,resp.description,"material_reference");
                    }
                });
            }
        });

        $form.find("[data-name='sup_order_detail_reference']").off().on('formField:changeVal',function(event){
            var productId = $form.find("[name='sup_order_detail_reference']").val();
            if(productId){
                gvf.endpoint("controller\\cp\\supOrderDetailListController::getPriceAndDescription",[productId],function(resp){
                    if(resp.status=="ok"){
                        setOfferLineData(resp.price,resp.description,"sup_order_detail_reference");
                    }
                });
            }
        });

        $form.find("[data-name='sup_order_detail_reference']").on('initialLoad',function(event){
            $form.find("[name='sup_order_detail_reference']").data('is_first_load','true');
        });
        $form.find("[data-name='tarifec_reference']").on('initialLoad',function(event){
            $form.find("[name='tarifec_reference']").data('is_first_load','true');
        });

        $form.find("[name='quantity'],[name='price'],[name='margin'],[name='discount'],[name='sale_price_unit']").change(function(event){
            updateLineForm($form.find("[name='force_sale']").prop("checked"));
        });

        $form.find("[name='force_sale']").change(function(event){
            $form.find("[name='margin']").prop("readonly",$(this).prop("checked"));
            $form.find("[name='discount']").prop("readonly",$(this).prop("checked"));
            $form.find("[name='sale_price_unit']").prop("readonly",!$(this).prop("checked"));
        });


        function updateLineForm(fullUpdate){
            var qty = $form.find("[name='quantity']").val();
            var discount = $form.find("[name='discount']").val();
            if(!fullUpdate){
                //Not forcing sale
                var cost = $form.find("[name='price']").val();
                var margin = $form.find("[name='margin']").val();
                if(parseFloat(discount)>0){
                    cost = cost-(discount*cost)/100;
                }
                var lineCost = parseFloat(cost)*qty;
                var unit = parseFloat(cost)+(margin*cost/100);
                var total = (unit.toFixed(6)*qty);
                $form.find("[name='line_price']").val(lineCost.toFixed(2));
                $form.find("[name='sale_price_unit']").val(unit.toFixed(6));
                $form.find("[name='sale_price']").val(total.toFixed(2));
            }else{
                //Forcing sale
                var unit = parseFloat($form.find("[name='sale_price_unit']").val());
                var total = (unit.toFixed(6)*qty);
                var cost = $form.find("[name='price']").val();
                var margin = ((unit/cost)-1)*100;
                var lineCost = parseFloat(cost)*qty;
                $form.find("[name='line_price']").val(lineCost.toFixed(2));

                $form.find("[name='sale_price_unit']").val(unit.toFixed(6));
                $form.find("[name='sale_price']").val(total.toFixed(2));
                if(margin > Number.MAX_SAFE_INTEGER){
                    $form.find("[name='margin']").val(100.00)
                } else {
                    $form.find("[name='margin']").val(margin.toFixed(2));
                }
            }
        }


        function setOfferLineData(price,description,fieldName){
            var $field = $form.find("[name='"+fieldName+"']");
            var is_first_load = $field.data("is_first_load");


            if(!is_first_load){
                setPrice(price);
                setDescription(description);
            }
            $field.data("is_first_load",'');
        }

        function setPrice(price){
            var salePriceUnitInput = $form.find("[name='sale_price_unit']");
            var costPriceUnitInput = $form.find("[name='price']");
            salePriceUnitInput.val(price);
            costPriceUnitInput.val(price);
        }

        function setDescription(description){
            var $description = $form.find("[name='description']");
            $description.val(description);
        }

        $form.submit(function(event){
            scrollToError();
        });

        function scrollToError(){
            if($form.find(".has-error") && $form.find(".has-error").length>0){
                $form.find(".has-error").get(0).scrollIntoView({
                    behavior:"smooth",block:"center"
                });
            }
        }

        function hideCostTypeReference(){
            var product_cost = 6;
            var cost_type = $form.find("[name='cost_type']").val();

            if(cost_type!=product_cost || cost_type==""){
                $form.find(scope+"__cost_product_type").hide();
            }else{
                $form.find(scope+"__cost_product_type").show();
            }
        }

        //first load
        hideCostTypeReference();
        if($form.find("[name='unit_items_line']").val()!=1){
            $form.find("[name='margin']").prop("readonly",$form.find("[name='force_sale']").prop("checked"));
            $form.find("[name='discount']").prop("readonly",$form.find("[name='force_sale']").prop("checked"));
        }
        if($form.find("[name='unit_items']").val()>0 || $form.find("[name='unit_items_line']").val()>0){
            if($form.find("[name='departure']").val()>0 && $form.find("[name='subdeparture']").val()==0){
                $form.find("[name='margin']").prop("readonly",true);
                $form.find("[name='discount']").prop("readonly",true);
                $form.find("[name='cus_offer_detail_unit_id']").prop("disabled",true);
                $form.find("[name='price']").prop("readonly",true);
            }
        }
        $form.find("[name='sale_price_unit']").prop("readonly",!$form.find("[name='force_sale']").prop("checked"));
        updateLineForm($form.find("[name='force_sale']").prop("checked"));

    });
}();


