//search forms
ui.searchForm = function(){
    var scope = ".js-search-form";

    ui.ready(
        scope,
        function($form){
            $form.find(".js-form-field").on(
                "formField:changeVal",
                function(ev){
                    $form.submit();
                }
            );

            $form.find(".js-form-field-input").after($("<input type='submit' />").hide());
        }
    );
}();