//form fields
ui.formFieldCustomObject = function(){
	var scope = ".js-form-field-custom-object";

	ui.ready(
		scope,
		function($field){
			var $input = $field.find(scope+"__input");
            var $info = $field.find(scope+"__info");
            var $value = $field.find(scope+"__value");
            var $modal = $field.find(scope+"__modal").clone().appendTo($("body"));
            var $modalBody = $modal.find(scope+"__modal-body");
            var $modalTitle = $modal.find(scope+"__modal-title");
            $modal.data("sourceField",$field);

            function getCopySeed(){
                var seed = [$field.data("controller")];
                if($field.data("seed"))
                    seed.push($field.data("seed"));
                return JSON.stringify(seed);
            }

            function copyValue(itemIndex){
                var v = $field.formFieldVal();
                if(itemIndex!==undefined){
                    v = v[itemIndex];
                }
                if(v){
                    var key = getCopySeed();
                    localStorage[key] = JSON.stringify(v);
                }
            }

            function setValue(value,noEvent,noReloadInfo){
                if($.isPlainObject(value) || $.isArray(value))
                    $value.val(JSON.stringify(value));
                else if(value && value.indexOf("[")==0 || value.indexOf("{")==0){
                    $value.val(value);
                    value = JSON.parse(value);
                }else{
                    $info.html("");
                    $value.val("");
                    value = null;
                }
                $field.toggleClass("has-value",$value.val()?true:false);
                $modal.modal("hide");
                if(!noReloadInfo){
                    gvf.endpoint(
                        $field.data("controller")+"::renderCustomObjectInfo",
                        [value,$field.data()],
                        function(resp){
                            if(resp.status=="ok"){
                                $info.html(resp.html);
                                ui.init($info);
                                if(resp.ordered_data){
                                    $field.find("[type='hidden']").val(resp.ordered_data);
                                }
                            }
                        }
                    );
                }else{ //no reload info: need to update data attribute for each item
                    var $items = $info.find(":not(.js-custom-object-info__item) .js-custom-object-info__item");
                    if($.isPlainObject(value)){
                        $items.data("data",value);
                    }else if($.isArray(value) && $items.length==value.length){
                        for(var i=0;i<value.length;i++){
                            $items.eq(i).data("data",value[i]);
                        }
                    }
                }
                if(!noEvent){
                    $field.trigger("formField:changeVal");
                }
            }

            function addValue(value,noEvent,replaceIndex){
                if($.isNumeric(replaceIndex)){ //replaceIndex must be a string path
                    replaceIndex = "["+replaceIndex+"]";
                }

                if($field.data("multiple")){
                    var values = [];
                    if($value.val())
                        values = JSON.parse($value.val());
                    if(replaceIndex!==undefined && /^[0-9a-zA-Z\[\].]+\+?$/i.test(replaceIndex)){
                        if(replaceIndex.indexOf("+")>-1){ //inserting at a position
                            if(/\[[0-9]+]\+$/i.test(replaceIndex)){ //ensure expression ends with [N]+
                                var lastIndex = replaceIndex.lastIndexOf("[");
                                var replaceListPath = replaceIndex.substring(0,lastIndex);
                                var replaceListIndex = parseInt(replaceIndex.substring(lastIndex+1,replaceIndex.length-2));
                                eval("values"+replaceListPath+".splice("+replaceListIndex+",0,value)");
                            }
                        }else{
                            eval("values"+replaceIndex+"=value");
                        }
                    }else{
                        values.push(value);
                    }
                    setValue(values,noEvent);
                }else{
                    setValue(value,noEvent);
                }
            }

            function removeItem(index){
                if($field.data("multiple")){
                    var items = $field.formFieldVal();
                    items.splice(index,1);
                    setValue(items,false);
                }else{
                    setValue("");
                }
            }

            function openModal(data,editPosition){
                if($.isNumeric(editPosition)){ //convert editPosition to a valid path
                    editPosition = "["+editPosition+"]";
                }

                $modal.modal("show");
                $modal.addClass("is-loading");
                var fieldData = $field.data();
                fieldData.editPosition = editPosition;
                fieldData.form = $field.closest(".js-form").getValues();
                var value = $field.find("[type='hidden']").val();
                gvf.endpoint(
                    $field.data("controller")+"::renderCustomObjectEditionForm",
                    [data,fieldData,value],
                    function(resp){
                        $modal.removeClass("is-loading");
                        if(resp.status=="ok"){
                            $modalTitle.html(resp.title);
                            $modalBody.html(resp.html);
                            $modalBody.find('.js-custom-object-edit__form').submit(
                                function(ev){
                                    ev.preventDefault();
                                    var $form = $(this);
                                    var isOk = $form.checkRestrictions();
                                    if(isOk){
                                        var v = $form.getValues();
                                        gvf.endpoint(
                                            $field.data("controller")+"::submitEditionForm",
                                            [v,$field.data()],
                                            function(resp){
                                                if(resp.status=="ok"){
                                                    if($field.data("multiple")){
                                                        addValue(resp.data,false,editPosition);
                                                    }else{
                                                        setValue(resp.data);
                                                    }
                                                }else if(resp.validation){
                                                    $form.applyValidationResponse(resp.validation);
                                                }else{
                                                    utils.alertEndpointResponse(resp);
                                                }
                                            }
                                        );
                                    }
                                }
                            );
                            ui.init($modalBody);
                        }else{
                            utils.alertEndpointResponse(resp);
                        }
                    }
                );
            }

            $field.data("openModal",openModal);
            $field.data("addValue",addValue);
            $field.data("setValue",setValue);
            $field.data("removeItem",removeItem);
            $field.data("copyValue",copyValue);

            $modal.find(scope+'__save').click(
                function(ev){
                    ev.preventDefault();
                    $modalBody.find('.js-custom-object-edit__form').submit();
                }
            );

            $field.data(
                "funcGetVal",
                function(){
                    var val = $value.val();
                    if(val){
                        try{
                            return JSON.parse($value.val());
                        }catch(e){
                            return "";
                        }
                    }else{
                        return "";
                    }
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    setValue(val);
                }
            );

            $field.find(scope+"__empty").click(
                function(ev){
                    ev.preventDefault();
                    setValue("");
                }
            );

            $field.find(scope+"__add").click(
                function(ev){
                    ev.preventDefault();
                    openModal();
                }
            );

            $field.find(scope+"__copy").click(
                function(ev){
                    ev.preventDefault();
                    copyValue();
                }
            );

            $field.find(scope+"__paste").click(
                function(ev){
                    ev.preventDefault();
                    var key = getCopySeed();
                    var val = JSON.parse(localStorage[key]);
                    //conversion between multiple/single selectors
                    if($field.data("multiple") && $.isPlainObject(val)){
                        val = [val];
                    }
                    if(!$field.data("multiple") && $.isArray(val)){
                        val = val[0];
                    }
                    setValue(val);
                }
            );

            $field.find(scope+"__worker-load").click(
                function(ev){
                    ev.preventDefault();
                    var addItemRef = function(id){
                        addValue({"ref":id});
                    };

                    listPage.selectModelItem(
                        "WorkerLibraryItem",
                        function(id){
                            addItemRef(id);
                        },
                        null,
                        null,
                        null,
                        function($modal){
                            $modal.on(
                                "workerItem:addRef",
                                function(ev,id){
                                    $modal.modal("hide");
                                    addItemRef(id);
                                }
                            );
                            $modal.on(
                                "workerItem:addValue",
                                function(ev,value){
                                    $modal.modal("hide");
                                    for(var i in value){
                                        addValue(value[i]);
                                    }
                                }
                            );
                        }
                    );
                }
            );

            $field.find(scope+"__worker-save").click(
                function(ev){
                    ev.preventDefault();
                    detailPage.openModal("WorkerLibraryItem",null,true,null,null,{"worker_group_id":$field.data("g"),"value":$field.formFieldVal()});
                }
            );

            //initial load
            setValue($value.val(),true);
        }
    );

    //extend jquery to get values
    jQuery.fn.extend(
        {
            "openModal":function(data,editPosition){
                if(this.data("openModal"))
                    return this.data("openModal")(data,editPosition);
            },
            "addValue":function(value,noEvent,editPosition){
                if(this.data("addValue"))
                    return this.data("addValue")(value,noEvent,editPosition);
            },
            "setValue":function(value,noEvent,noReloadInfo){
                if(this.data("setValue"))
                    return this.data("setValue")(value,noEvent,noReloadInfo);
            },
            "copyValue":function(itemIndex){
                if(this.data("copyValue"))
                    return this.data("copyValue")(itemIndex);
            },
            "removeItem":function(index){
                if(this.data("removeItem"))
                    return this.data("removeItem")(index);
            }
        }
    );
}();