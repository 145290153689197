var cusProjectListPage = function(){
    var scope = ".js-cus-project-list-page";
    ui.ready(scope,function($page){
        $page.find(".js-cus-project-list-page__download").unbind().click(function(event){
            var $eventTarget = $(event.target);
            if($eventTarget.hasClass("download-project-documents") || $eventTarget.parent().hasClass("download-project-documents")){
                var projectId = $eventTarget.parent().data("id");
                if(!projectId){
                    projectId = $eventTarget.parent().parent().data("id");
                }
                gvf.endpoint("controller\\cp\\cusProjectListController::downloadAttachedDocuments",
                    [projectId],
                    function(resp){
                    if(resp.status=="ok"){
                        window.location = resp.url;
                    }else{
                        utils.alertEndpointResponse(resp);
                    }
                });
            }
        });

        //end ready
    });
}();