var supplierDocumentsDetailPage = function(){
    var scope = ".js-supplier-sup-document-type-detail-page";
    ui.ready(scope,function($page){
        $page.find("[data-name='created']").on('formField:changeVal',(function(ev){
            ev.preventDefault();
            var fileTypeId = $page.find("[data-name='sup_document_type_id']").formFieldVal();
            var created = $page.find("[data-name='created']").formFieldVal();
            if(created){
                gvf.endpoint("controller\\cp\\supplierDocumentsDetailController::getExpirationDate",[fileTypeId,created],function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='expiration_date']").formFieldSetVal(resp.expiration_date);
                        $page.find("[name='expiration_date']").val(resp.expiration_date);
                    }
                })
            }
        }));

        $page.find(scope+"__delete").click(
            function(ev){
                ev.preventDefault();
                var objectId = $page.find("[name=id]").val();
                if(!objectId)
                    return;
                swal({
                    title: "¿Estás seguro?",
                    text: "Se eliminará este elemento de forma permanente",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sí, eliminar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                    showLoaderOnConfirm:true
                }, function(isConfirm){
                    if(isConfirm){
                       gvf.endpoint("controller\\cp\\supplierDocumentsDetailController::clearDocument", [objectId], function (resp){
                           if(resp.status == 'ok'){
                               window.location.href = resp.url;
                           } else {
                               utils.alertEndpointResponse(resp);
                           }
                       });
                    }
                });
            }
        );
    });
}();

