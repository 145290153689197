var simalgaDocumentListPage = function(){
    var scope = ".js-simalga-document-list-page";
    ui.ready(
        scope,
        function($page){
            $page.find(scope+"__copy-link").click(
                function(ev){
                    ev.preventDefault();
                    var el = document.createElement('textarea');
                    el.value = "http://base.local.goventure.es/downloaddocs.html";
                    el.setAttribute('readonly', '');
                    el.style = {position: 'absolute', left: '-9999px'};
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
                    utils.alertEndpointResponse({"status":"ok", "message":"Link Copiado"});

                }
            );
        }
    );
}();

