var supOrderDetailPage = function(){
	var scope = ".js-sup-order-detail-page";
	ui.ready(
        scope,
		function($page){
            var id = $page.find("[name=id]").val();
            $page.find(scope+"__confirm-reception").click(
            	function(ev){
            		ev.preventDefault();
            		ev.stopPropagation();
                    page.showLoading();
                    var $button = $(this);
                    $button.prop("disabled",true);
					gvf.endpoint(
                        "controller\\cp\\supOrderListController::confirmReception",
						[id],
						function(resp){
                            page.hideLoading();
                            $button.prop("disabled",false);
                            utils.alertEndpointResponse(resp);
                            if(resp.status=="ok")
                                $button.remove();
                        }
					);
				}
			);

            $page.find(scope+"__send-order").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    var $button = $(this);
                    $button.prop("disabled",true);
                    gvf.endpoint(
                        "controller\\cp\\supOrderListController::sendOrder",
                        [id],
                        function(resp){
                            $button.prop("disabled",false);
                            utils.alertEndpointResponse(resp);
                            if(resp.status=="ok")
                                $button.remove();
                        }
                    );
                }
            );
		}
	);
}();

