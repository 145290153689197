var cpRoleDetailPage = function(){
	var scope = ".js-cp-role-detail-page";
	ui.ready(
        scope,
		function($page){
            var id = $page.find("[name=id]").val();
            $page.find(scope+"__section-permission-check").change(
                function(){
                    var checkInput = this;
                    gvf.endpoint(
                        "controller\\cp\\cpRoleDetailController::changePermissionStatusSection",
                        [id,$(this).data("id")],
                        function(resp){
                            $(checkInput).closest(scope+"__section-permission").removeClass("has-status-1 has-status-2 has-status-3 has-status-4").addClass("has-status-"+resp.new_status);
                            checkInput.checked = (resp.new_status==1);
                        }
                    );
                }
            );

            $page.find(scope+"__special-permission-check").change(
                function(){
                    var checkInput = this;
                    gvf.endpoint(
                        "controller\\cp\\cpRoleDetailController::changePermissionStatusSpecial",
                        [id,$(this).data("id")],
                        function(resp){
                            $(checkInput).closest(scope+"__special-permission").removeClass("has-status-1 has-status-2 has-status-3 has-status-4").addClass("has-status-"+resp.new_status);
                            checkInput.checked = (resp.new_status==1);
                        }
                    );
                }
            );
		}
	);
}();

