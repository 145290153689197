var cpUserDetailPage = function(){
    var scope = ".js-cp-user-detail-page";

    function updateIsActive($page,active){
        $page.find("form").css("opacity",active?1:0.7);
    }

    ui.ready(
        scope,
        function($page){
            var id = $page.find("[name=id]").val();
            $page.find(scope+"__section-permission-check").change(
                function(){
                    var checkInput = this;
                    gvf.endpoint(
                        "controller\\cp\\cpUserDetailController::changePermissionStatusSection",
                        [id,$(this).data("id")],
                        function(resp){
                            $(checkInput).closest(scope+"__section-permission").removeClass("has-status-1 has-status-2 has-status-3 has-status-4").addClass("has-status-"+resp.new_status);
                            checkInput.checked = (resp.new_status==1 || resp.new_status==3);
                        }
                    );
                }
            );
            $page.find(scope+"__special-permission-check").change(
                function(){
                    var checkInput = this;
                    gvf.endpoint(
                        "controller\\cp\\cpUserDetailController::changePermissionStatusSpecial",
                        [id,$(this).data("id")],
                        function(resp){
                            $(checkInput).closest(scope+"__special-permission").removeClass("has-status-1 has-status-2 has-status-3 has-status-4").addClass("has-status-"+resp.new_status);
                            checkInput.checked = (resp.new_status==1 || resp.new_status==3);
                        }
                    );
                }
            );
            $page.find(scope+"__notification-check").change(
                function(){
                    var checkInput = this;
                    gvf.endpoint(
                        "controller\\cp\\cpUserDetailController::changeNotificationCheck",
                        [id,$(this).data("id"),checkInput.checked],
                        function(resp){
                            if(resp.status!="ok")
                                checkInput.checked = !checkInput.checked;
                        }
                    );
                }
            );

            $page.find("[name='cp_role_id']").change(
                function(){
                    var value = $(this).val();
                    gvf.endpoint(
                        "controller\\cp\\cpUserDetailController::changeCpUserTypeBasedOnCpRole",
                        [value],
                        function(resp){
                            if(resp.status=="ok"){
                                $page.find("[name='cp_user_type']").val(resp.cp_user_type);
                                $page.find("[name='cp_user_type']").trigger( "formField:changeVal" );
                            }
                        }
                    );
                }
            );

            var $setPassModal = $page.find(scope+"__set-pass-modal");
            $page.find(scope+"__set-pass-confirm").click(
                function(ev){
                    ev.preventDefault();
                    var $newPass = $setPassModal.find("[name=setNewPass]");
                    gvf.endpoint(
                        "cp\\cpUserDetailController::setUserPass",
                        [id,$newPass.val()],
                        function(resp){
                            if(resp.status=="ok"){
                                $setPassModal.modal("hide");
                                $newPass.val("");
                                swal(
                                    {
                                        type: "info",
                                        title: "",
                                        text: "Contraseña cambiada"
                                    }
                                );
                            }else{
                                utils.alertEndpointResponse(resp);
                            }

                        }
                    );
                }
            );

            $page.find(scope+"__unBlockLogin").click(
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                        "cp\\cpUserDetailController::resetLoginAttempts",
                        [id],
                        function(resp){
                            window.location.reload();
                        }
                    );
                }
            );
            $page.find(scope+"__reset-pass").click(
                function(ev){
                    ev.preventDefault();
                    swal({
                        title: "Enviar e-mail al usuario",
                        text: "¿Enviar e-mail al usuario con instrucciones para asignar contraseña?",
                        type: "info",
                        showCancelButton: true,
                        confirmButtonText: "Enviar e-mail",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function(isConfirm){
                        if(isConfirm){
                            page.showLoading();
                            gvf.endpoint(
                                "cp\\cpUserDetailController::resetUserPass",
                                [id],
                                function(resp){
                                    page.hideLoading();
                                    utils.alertEndpointResponse(resp);
                                }
                            );
                        }
                    });
                }
            );

            var $activeToggle = $page.find("[name=is_active_toggle]");
            if($activeToggle.length>0){
                $activeToggle.change(
                    function(){
                        var active = this.checked;
                        updateIsActive($page,active);
                        gvf.delay(
                            300,
                            function(){
                                gvf.endpoint(
                                    "controller\\cp\\cpUserDetailController::changeIsActiveUser",
                                    [id],
                                    function(resp){
                                        utils.alertEndpointResponse(resp);
                                    }
                                );
                            }
                        );
                    }
                );
                updateIsActive($page,$activeToggle.prop("checked"));
            }
        }
    );
}();

