var workflowDetailPage = function(){
    var scope = ".js-workflow-detail-page";

    ui.ready(
        scope,
        function($page){
            var $container = $page.find(scope+"__diagram");
            var $eventMenu = $page.find(scope+"__event-menu");
            var $actionMenu = $page.find(scope+"__action-menu");
            var $event = $page.find("[data-name=event]");
            var $condition = $page.find("[data-name=condition]");
            var $action = $page.find("[data-name=action]");
            var $editEventModal = $page.find(scope+"__edit-event-modal");
            var $editActionModal = $page.find(scope+"__edit-action-modal");
            var currentActionShape;
            var eventShape = null;
            var actionShapes = {};
            var eventIcons = $container.data("event-icons");
            var actionIcons = $container.data("action-icons");
            var iconBlankSpace = "          ";

            var graph = new joint.dia.Graph();
            var paper = new joint.dia.Paper({
                el: $container.get(0),
                height: 600,
                width: "100%",
                gridSize: 10,
                model: graph
            });

            function showEventMenu(model){
                $eventMenu.find(scope+"__event-menu-edit").unbind().click(
                    function(){
                        $event.openModal($event.formFieldVal());
                        hideEventMenu();
                    }
                );
                $eventMenu.find(scope+"__event-menu-remove").unbind().click(
                    function(){
                        $event.formFieldSetVal("");
                        hideEventMenu();
                    }
                );
                var menuX = model.position().x+model.size().width;
                if(model.position().x+model.size().width+$eventMenu.outerWidth()>$container.width()){
                    menuX = model.position().x-$eventMenu.outerWidth();
                }
                $eventMenu.css({"top":model.position().y,"left":menuX}).show();
                hideActionMenu();
            }

            function hideEventMenu(){
                $eventMenu.hide();
            }

            function toggleEventMenu(model){
                if($eventMenu.is(":visible")){
                    hideEventMenu();
                }else{
                    showEventMenu(model);
                }
            }

            function showActionMenu(model){
                $actionMenu.find(scope+"__action-menu-edit").unbind().click(
                    function(){
                        var info = getActionJsonPathInfo(model.jsonPath);
                        $action.openModal(info.currentAction,model.jsonPath);
                        hideActionMenu();
                    }
                );
                $actionMenu.find(scope+"__action-menu-add-after,"+scope+"__action-menu-add-before").unbind().click(
                    function(){
                        var info = getActionJsonPathInfo(model.jsonPath);
                        var newItemIndex = info.currentActionIndex;
                        if($(this).is(scope+"__action-menu-add-after")){
                            newItemIndex++;
                        }
                        $action.openModal(null,info.currentActionListPath+"["+newItemIndex+"]+");
                        hideActionMenu();
                    }
                ).toggle(!model.isAsync);

                var info = getActionJsonPathInfo(model.jsonPath);
                $actionMenu.find(scope+"__action-menu-up").unbind().click(
                    function(){
                        if(info.currentActionIndex>0){
                            var a = info.currentActionIndex;
                            var b = info.currentActionIndex-1;
                            info.currentActionList.splice(a, 1, info.currentActionList.splice(b, 1, info.currentActionList[a])[0]);
                            $action.formFieldSetVal(info.actions);
                        }
                        hideActionMenu();
                        currentActionShape = null;
                    }
                ).toggle(info.currentActionIndex>0);
                $actionMenu.find(scope+"__action-menu-down").unbind().click(
                    function(){
                        if(info.currentActionIndex<info.currentActionList.length-1){
                            var a = info.currentActionIndex;
                            var b = info.currentActionIndex+1;
                            info.currentActionList.splice(a, 1, info.currentActionList.splice(b, 1, info.currentActionList[a])[0]);
                            $action.formFieldSetVal(info.actions);
                        }
                        hideActionMenu();
                        currentActionShape = null;
                    }
                ).toggle(info.currentActionIndex<info.currentActionList.length-1);

                $actionMenu.find(scope+"__action-menu-remove").unbind().click(
                    function(){
                        var info = getActionJsonPathInfo(currentActionShape.jsonPath);
                        if(info.actions.length>1){
                            info.currentActionList.splice(info.currentActionIndex,1);
                            $action.formFieldSetVal(info.actions);
                        }else{
                            $action.formFieldSetVal("");
                        }
                        hideActionMenu();
                        currentActionShape = null;
                    }
                );
                var menuX = model.position().x+model.size().width;
                if(model.position().x+model.size().width+$actionMenu.outerWidth()>$container.width()){
                    menuX = model.position().x-$actionMenu.outerWidth();
                }
                currentActionShape = model;
                $actionMenu.css({"top":model.position().y,"left":menuX}).show();
                hideEventMenu();
            }

            function getActionJsonPathInfo(jsonPath){
                var actions = $action.formFieldVal();
                var lastIndex = jsonPath.lastIndexOf("[");
                var listPath = jsonPath.substring(0,lastIndex);
                var info = {};
                info.actions = actions;
                info.currentAction = eval("actions"+jsonPath);
                info.currentActionIndex = parseInt(jsonPath.substring(lastIndex+1,jsonPath.length-1));
                info.currentActionListPath = listPath;
                info.currentActionList = eval("actions"+info.currentActionListPath);
                return info;
            }

            function hideActionMenu(){
                $actionMenu.hide();
            }

            function toggleActionMenu(model){
                if($actionMenu.is(":visible")){
                    hideActionMenu();
                }else{
                    showActionMenu(model);
                }
            }

            function updatePaperSize(){
                var maxHeight = 0;
                if(eventShape && eventShape.position()){
                    maxHeight = eventShape.position().y+eventShape.size().height;
                }
                for(var i in actionShapes){
                    var h = actionShapes[i].position().y+actionShapes[i].size().height;
                    if(h>maxHeight){
                        maxHeight = h;
                    }
                }
                paper.setDimensions("100%",Math.max(maxHeight+100,600));
            }

            function refreshEventShapes(){
                var value = $event.formFieldVal();
                var label = "(Seleccionar evento)";
                if(value){
                    label = (value.name?value.name:"Evento");
                    if(parseInt(value.x)>0 && parseInt(value.y)>0){
                        var x = parseInt(value.x);
                        var y = parseInt(value.y);
                        eventShape.position(x,y);
                    }
                }
                eventShape.icon.css({"left":eventShape.position().x,"top":eventShape.position().y});
                eventShape.attr("label/text",iconBlankSpace+label);
            }

            function refreshActionShapes(){
                var value = $action.formFieldVal();
                for(var i in actionShapes){
                    actionShapes[i].remove();
                }
                $container.find(scope+"__shape-icon.is-action").remove();
                actionShapes = {};

                if(value.length>0){
                    var previousSyncPath = null;
                    for(var i in value){
                        var label = null;
                        if(i>0){
                            if(value[i-1].w=="conditional_action"){ //in conditional actions: link with last child of each branch
                                previousSyncPath = [];
                                if(value[i-1].yesAction && value[i-1].yesAction.length>0){
                                    previousSyncPath.push("["+(i-1)+"].yesAction["+(value[i-1].yesAction.length-1)+"]");
                                }else{
                                    previousSyncPath.push("["+(i-1)+"]");
                                    label = "Sí";
                                }
                                if(value[i-1].noAction && value[i-1].noAction.length>0){
                                    previousSyncPath.push("["+(i-1)+"].noAction["+(value[i-1].noAction.length-1)+"]");
                                }else{
                                    previousSyncPath.push("["+(i-1)+"]");
                                    label = "No";
                                }
                            }else if(!actionShapes["["+(i-1)+"]"].isAsync){
                                previousSyncPath = "["+(i-1)+"]";
                            }
                        }
                        addActionShape("["+i+"]",previousSyncPath,label);
                    }
                }else{
                    addActionShape("[0]");
                }
                updatePaperSize();
            }

            function addEventShape(){
                var value = $event.formFieldVal();
                var label = "(Seleccionar evento)";
                if(value){
                    label = value.name?value.name:"Evento";
                }

                if(eventShape){
                    eventShape.remove();
                }

                var x = $container.width()/2-100;
                var y = 50;
                if(value && parseInt(value.x)>0 && parseInt(value.y)>0){
                    x = parseInt(value.x);
                    y = parseInt(value.y);
                }

                eventShape = new joint.shapes.standard.Rectangle({
                    position: { x: x, y: y },
                    size: { width: 200, height: 70 },
                    attrs: {
                        body: {
                            fill: {
                                type: 'linearGradient',
                                stops: [
                                    { offset: '0%', color: '#f7a07b' },
                                    { offset: '100%', color: '#fe8550' }
                                ],
                                attrs: { x1: '0%', y1: '0%', x2: '0%', y2: '100%' }
                            },
                            stroke: '#ed8661',
                            strokeWidth: 2
                        },
                        label: {
                            text: iconBlankSpace+label,
                            fill: '#f0f0f0',
                            fontSize: 14,
                            fontWeight: 'lighter',
                            fontVariant: 'small-caps'
                        }
                    }
                });


                var icon = "mdi mdi-flash";
                if(value){
                    icon = eventIcons[value.w];
                }
                eventShape.icon = $("<i class='js-workflow-detail-page__shape-icon cp-workflow-detail-page__shape-icon is-event "+icon+"'></i>").appendTo($container);
                eventShape.icon.css({"left":x,"top":y});

                eventShape.isEvent = true;
                eventShape.addTo(graph);
                eventShape.on(
                    'change:position',
                    function(element, position){
                        gvf.delay(100,
                            function(){
                                var value = $event.formFieldVal();
                                value.x = element.position().x;
                                value.y = element.position().y;
                                $event.setValue(value,true,true);
                                updatePaperSize();
                                hideEventMenu();
                            }
                        );
                        element.icon.css({"left":position.x,"top":position.y});
                    }
                );
            }

            /**
             * Creates conditional shape
             * @returns {Polygon}
             */
            function createActionShape(x,y,label){
                var shapeOptions = {
                    position: { x: x, y: y },
                    size: { width: 200, height: 70 },
                    attrs: {
                        body: {
                            fill: {
                                type: 'linearGradient',
                                stops: [
                                    { offset: '0%', color: '#b5acf9' },
                                    { offset: '100%', color: '#9687fe' }
                                ],
                                attrs: { x1: '0%', y1: '0%', x2: '0%', y2: '100%' }
                            },
                            stroke: '#958cd9',
                            strokeWidth: 2
                        },
                        label: {
                            text: iconBlankSpace+label,
                            fill: '#f0f0f0',
                            fontSize: 14,
                            fontWeight: 'lighter',
                            fontVariant: 'small-caps'
                        }
                    }
                };
                return new joint.shapes.standard.Rectangle(shapeOptions);
            }

            /**
             * Creates conditional shape
             * @returns {Polygon}
             */
            function createConditionalShape(x,y,label){
                var shapeOptions = {
                    position: { x: x, y: y },
                    size: { width: 200, height: 70 },
                    attrs: {
                        body: {
                            fill: {
                                type: 'linearGradient',
                                stops: [
                                    { offset: '0%', color: '#ff8' },
                                    { offset: '100%', color: '#aa3' }
                                ],
                                attrs: { x1: '0%', y1: '0%', x2: '0%', y2: '100%' }
                            },
                            stroke: '#990',
                            strokeWidth: 2
                        },
                        label: {
                            text: label,
                            fill: '#333',
                            fontSize: 14,
                            fontWeight: 'lighter',
                            fontVariant: 'small-caps'
                        }
                    }
                };
                shapeOptions.attrs.body.refPoints = '0,10 10,0 20,10 10,20';
                return new joint.shapes.standard.Polygon(shapeOptions);
            }

            /**
             * Creates conditional shape
             * @returns {Polygon}
             */
            function createWaitShape(x,y,label){
                var shapeOptions = {
                    position: { x: x, y: y },
                    size: { width: 200, height: 70 },
                    attrs: {
                        body: {
                            fill: {
                                type: 'linearGradient',
                                stops: [
                                    { offset: '0%', color: '#8ff' },
                                    { offset: '100%', color: '#3aa' }
                                ],
                                attrs: { x1: '0%', y1: '0%', x2: '0%', y2: '100%' }
                            },
                            stroke: '#099',
                            strokeWidth: 2
                        },
                        label: {
                            text: iconBlankSpace+label,
                            fill: '#333',
                            fontSize: 14,
                            fontWeight: 'lighter',
                            fontVariant: 'small-caps'
                        }
                    }
                };
                return new joint.shapes.standard.Ellipse(shapeOptions);
            }

            function addActionShape(jsonPath,jsonPathParent,linkLabel,offset){
                var info = getActionJsonPathInfo(jsonPath);
                var label = "(Seleccionar acción)";
                var previousShapes = [eventShape];
                if(jsonPathParent){
                    if($.isArray(jsonPathParent)){
                        previousShapes = [];
                        for(var i in jsonPathParent){
                            previousShapes.push(actionShapes[jsonPathParent[i]]);
                        }
                    }else{
                        previousShapes = [actionShapes[jsonPathParent]];
                    }
                }

                var x = 0; //this will be mean x of previous shapes
                var y = 0; //this will be max y of previous shapes
                for(var i in previousShapes){
                    if(previousShapes[i].position().y>y){
                        y = previousShapes[i].position().y;
                    }
                    x += previousShapes[i].position().x;
                }
                x = x/previousShapes.length;
                if(offset){
                    x += offset*100;
                }
                y += 100;

                if(info.currentAction){
                    label = info.currentAction.name?info.currentAction.name:"Acción";
                    if(parseInt(info.currentAction.x)>0 && parseInt(info.currentAction.y)>0){
                        x = parseInt(info.currentAction.x);
                        y = parseInt(info.currentAction.y);
                    }
                }

                var actionShape;
                var workerType = "";
                if(info && info.currentAction){
                    workerType = info.currentAction.w;
                }
                if(workerType=="conditional_action"){
                    actionShape = createConditionalShape(x,y,label);
                }else if(workerType=="wait_for_event_action" || workerType=="wait_until_condition_action" || workerType=="wait_time_action"){
                    actionShape = createWaitShape(x,y,label);
                    actionShape.isAsync = true;
                }else{
                    actionShape = createActionShape(x,y,label);
                }

                actionShape.isAction = true;
                actionShape.jsonPath = jsonPath;
                var icon = "mdi mdi-playlist-play";
                if(info.currentAction){
                    icon = actionIcons[workerType];
                }
                if(workerType!="conditional_action"){
                    actionShape.icon = $("<i class='js-workflow-detail-page__shape-icon cp-workflow-detail-page__shape-icon is-action "+icon+"'></i>").appendTo($container);
                    actionShape.icon.css({"left":x,"top":y});
                }
                actionShape.addTo(graph);

                actionShape.on(
                    'change:position',
                    function(element, position) {
                        gvf.delay(100,
                            function(){
                                var info = getActionJsonPathInfo(element.jsonPath);
                                if(info.currentAction){ //might be an empty node
                                    info.currentAction.x = element.position().x;
                                    info.currentAction.y = element.position().y;
                                    $action.setValue(info.actions,true,true);
                                    updatePaperSize();
                                    hideActionMenu();
                                }
                            }
                        );
                        if(actionShape.icon){
                            actionShape.icon.css({"left":position.x,"top":position.y});
                        }
                    }
                );
                actionShapes[jsonPath] = actionShape;

                //a link for each previous shape
                for(var i in previousShapes){
                    var linkShape = new joint.shapes.standard.Link({
                        source: { id: previousShapes[i].id },
                        target: { id: actionShape.id },
                        router: { name: 'manhattan' },
                        connector: { name: 'rounded' },
                        attrs: {
                            line: {
                                stroke: '#333333',
                                strokeWidth: 2
                            }
                        }
                    });

                    if(linkLabel){
                        linkShape.appendLabel({
                            markup: [
                                {
                                    tagName: 'text',
                                    selector: 'label'
                                }
                            ],
                            attrs: {
                                label: {
                                    text: linkLabel, // text to show
                                    fill: '#000'
                                }
                            },
                            position: {
                                distance: 0.5, // midway on the connection path
                                offset: {
                                    x: 10, // 10 local x units to the right
                                    y: -10 // 10 local y units above
                                },
                                angle: 45, // rotate by 45 degrees clockwise
                                args: {
                                    keepGradient: true, // auto-rotate by path slope at distance
                                    ensureLegibility: true // auto-rotate label if upside-down
                                }
                            }
                        });
                    }

                    linkShape.addTo(graph);
                }

                function addSubActionList(arrayName,label,offset){
                    if(info.currentAction[arrayName]){
                        var previousSyncPath = jsonPath;
                        for(var a in info.currentAction[arrayName]){
                            var previousPath = jsonPath+"."+arrayName+"["+(a-1)+"]";
                            if(a>0 && !actionShapes[previousPath].isAsync){
                                previousSyncPath = previousPath;
                            }
                            addActionShape(jsonPath+"."+arrayName+"["+a+"]",previousSyncPath,previousSyncPath==jsonPath?label:null,offset);
                        }
                    }
                }

                //complex actions
                if(workerType=="conditional_action"){
                    addSubActionList("yesAction","Sí",-1);
                    addSubActionList("noAction","No",1);
                }else if(workerType=="wait_for_event_action"){
                    addSubActionList("action","Evento lanzado",-1);
                    addSubActionList("timeoutAction","Superado tiempo",1);
                }else if(workerType=="wait_until_condition_action"){
                    addSubActionList("action","Cumple condición",-1);
                    addSubActionList("timeoutAction","Superado tiempo",1);
                }else if(workerType=="wait_time_action"){
                    addSubActionList("timeoutAction","");
                }
            }

            paper.on(
                'cell:pointerclick',
                function(cellView, evt, x, y) {
                    if(cellView.model.isEvent){
                        var value = $event.formFieldVal();
                        if(value){
                            toggleEventMenu(cellView.model);
                        }else{
                            $event.openModal();
                        }
                    }else if(cellView.model.isAction){
                        var value = $action.formFieldVal();
                        currentActionShape = cellView.model;
                        var info = getActionJsonPathInfo(currentActionShape.jsonPath);
                        if(info.currentAction){
                            showActionMenu(currentActionShape);
                        }else{
                            $action.openModal();
                        }
                    }
                }
            );

            $event.on(
                "formField:changeVal",
                function(){
                    refreshEventShapes();
                }
            );

            $action.on(
                "formField:changeVal",
                function(){
                    refreshActionShapes();
                }
            );

            $container.click(
                function(ev){
                    if($(ev.target).is("svg")){
                        hideActionMenu();
                        hideEventMenu();
                    }
                }
            );

            //delay a little to compute dimensions correctly
            setTimeout(
                function(){
                    addEventShape();
                    addActionShape("[0]");
                },
                500
            );
        }
    );


}();