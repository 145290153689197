var taskListPage = function(){
	var scope = ".js-task-list-page";
	ui.ready(
        scope,
		function($page){
            $page.find(scope+"__execute").click(
                function(ev){
                    ev.preventDefault();
                    var $modal = $(scope+"__modal");
                    var taskName = $(this).data("task");
                    gvf.endpoint(
                        "controller\\cp\\taskListController::getForm",
                        [taskName],
                        function(resp){
                            if(resp.status == "ok"){
                                var $modalBody = $modal.find(scope+"__modal-body");
                                $modalBody.html(resp.html);
                                ui.init($modalBody);
                                $modal.modal("show");
                                $modal.find(scope + "__execute-task,"+scope+"__execute-task-bg").unbind().click(
                                    function(){
                                        var data = $modal.find(scope+"__form").getValues();
                                        gvf.endpoint(
                                            "controller\\cp\\taskListController::executeTask",
                                            [taskName,data,$(this).is(scope+"__execute-task-bg")],
                                            function(resp){
                                                utils.alertEndpointResponse(resp);
                                                if(resp.status == "ok"){
                                                    $modal.modal("hide");
                                                }
                                            }
                                        );
                                    }
                                );
                            }else{
                                utils.alertEndpointResponse(resp);
                            }
                        }
                    );
                }
            );
		}
	);
}();

