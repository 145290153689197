var cusFacilityItemDetailPage = function(){
    var scope = ".js-cus-facility-item-detail-page";
    ui.ready(
        scope,
        function($page){

            $page.find("[data-name='parent_id']").on('formField:changeVal',function(event){
                var $parentId = $page.find("[name='parent_id']");
                var $customerId = $page.find("[data-name='customer_id']");
                var $facilityid = $page.find("[data-name='cus_facility_id']");
                gvf.endpoint(
                    "controller\\cp\\cusFacilityItemDetailController::getParentCustomerAndFacilityInfo",
                    [$parentId.val()],
                    function(resp){
                        if(resp.status == "ok"){
                            $customerId.formFieldSetVal(resp.customer_id);
                            $facilityid.formFieldSetVal(resp.cus_facility_id);
                        }
                    }
                );
            });


        }
    );
}();