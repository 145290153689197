var cpDropzone = function(){
    var scope = ".js-dropzone";

    ui.ready(
        scope,
        function($dropzone){
            $dropzone.addClass("dropzone");
            var isReadonly = $dropzone.hasClass("is-readonly");
            var isSingleFile = $dropzone.hasClass("is-single-file");
            var $input = $("[name='"+$dropzone.data("input")+"']");
            var config = $dropzone.data("config");
            var fileTypeId = $dropzone.data("filetypeid");

            if(isReadonly)
                config.dictDefaultMessage = "";
            config.url = "/files/upload/";
            if(fileTypeId)
                config.url += fileTypeId+"/";
            config.addRemoveLinks = !isReadonly;
            config.init = function() {
                this.on(
                    "success",
                    function(ev,response){
                        if(response.status=="ok" && response.files && response.files.length>0){
                            if(isSingleFile){
                                $input.val(response.files[0]);
                            }else{
                                var files = [];
                                if($input.val()){
                                    files = JSON.parse($input.val());
                                }
                                files = files.concat(response.files);
                                $input.val(JSON.stringify(files));
                            }
                            if(response.files.length==1){
                                ev.id = response.files[0];
                            }
                        }
                    }
                );
                this.on(
                    "addedfile",
                    function(file) {
                        file.previewElement.addEventListener("click",function(ev){
                            if(ev.target.nodeName=="SPAN" && !isReadonly){
                                swal({
                                    title: "Cambiar nombre",
                                    type: "input",
                                    showCancelButton: true,
                                    closeOnConfirm: true,
                                    inputPlaceholder: "Nombre del archivo",
                                    inputValue: file.name
                                }, function (inputValue) {
                                    if (inputValue === false) return false;
                                    if (inputValue === "") {
                                        swal.showInputError("El nombre no puede estar vacío");
                                        return false
                                    }
                                    gvf.endpoint(
                                        "controller\\cp\\repFileController::changeFileName",
                                        [file.id,inputValue],
                                        function(resp){
                                            if(resp.status!="ok"){
                                                utils.alertEndpointResponse(resp);
                                            }else{
                                                $(file.previewElement).find("[data-dz-name]").html(inputValue);
                                                file.name = inputValue;
                                            }
                                        }
                                    );
                                    return true;
                                });
                            }else{
                                if(file.detailUrl){
                                    if(file.detailUrl.indexOf("?d=1")==-1)
                                        window.open(file.detailUrl);
                                    else
                                        window.location = file.detailUrl;
                                }
                            }
                        });
                    }
                );
                this.on(
                    "removedfile",
                    function(ev){
                        if(ev.id){
                            if(isSingleFile){
                                if($input.val()==ev.id){
                                    $input.val("");
                                }
                            }else{
                                var files = [];
                                if($input.val()){
                                    files = JSON.parse($input.val());
                                }
                                var i = files.indexOf(ev.id);
                                if(i==-1){
                                    i = files.indexOf(parseInt(ev.id));
                                }
                                if(i>=0){
                                    files.splice(i,1);
                                }
                                $input.val(JSON.stringify(files));
                            }
                        }
                    }
                );
                var files = $dropzone.data('files');
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    file.accepted = true;
                    this.files.push(file);
                    this.emit('addedfile', file);
                    this.createThumbnailFromUrl(file, file.url,null,"anonymous");
                    this.emit('complete', file);
                }
                $dropzone.data('dropzone',this);
            };
            $dropzone.dropzone(config);
            if(isReadonly){
                $dropzone.css("pointer-events","none").find(".dz-preview").css("pointer-events","auto");
            }
        }
    );
}();