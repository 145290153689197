var myProfilePage = function(){
	var scope = ".js-my-profile-page";
	ui.ready(
        scope,
		function($page){
            $page.find(scope+"__change-pass").click(
            	function(ev){
            		ev.preventDefault();
                    $(this).toggle();
                    $page.find(scope+"__set-pass-form").toggle();
				}
			);

            $page.find(scope+"__set-pass-form").submit(
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                    	"controller\\cp\\myProfileController::changePass",
						[$page.find("[name=current_pass]").val(),$page.find("[name=new_pass]").val(),$page.find("[name=new_pass_confirm]").val()],
						function(resp){
                            utils.alertEndpointResponse(resp);
						}
					);
                }
            );

            $page.find(scope+"__user-data-form").submit(
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                        "controller\\cp\\myProfileController::saveMyData",
                        [$(this).getValues()],
                        function(resp){
                            utils.alertEndpointResponse(resp);
                        }
                    );
                }
            );
		}
	);
}();

