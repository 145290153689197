var supInvoiceDetailPage = function(){
	var scope = ".js-sup-invoice-detail-page";
	ui.ready(
        scope,
		function($page){
        	var id = $page.find("[name=id]").val();
        	var $form = $page.find("form");
            $page.find(scope+"__send-invoice").click(
            	function(ev){
            		ev.preventDefault();
            		ev.stopPropagation();
                    page.showLoading();
            		var $button = $(this);
                    $button.prop("disabled",true);
					gvf.endpoint(
                        "controller\\cp\\supInvoiceListController::sendInvoice",
						[$button.data("id")],
						function(resp){
                            page.hideLoading();
                            $button.prop("disabled",false);
                            if(resp.status=="ok"){
                                window.location.reload();
                            }else
                                utils.alertEndpointResponse(resp);
                        }
					);
				}
			);

            $page.find(scope+"__mark-as-paid").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    var $button = $(this);
                    swal({
                        title: "¿Marcar como pagada?",
                        text: "Se notificará al proveedor que la factura ha sido pagada",
                        showCancelButton: true,
                        confirmButtonColor: "#00bb00",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        showLoaderOnConfirm: true
                    }, function(){
                        $button.remove();
                        gvf.endpoint(
                            "controller\\cp\\supInvoiceDetailController::markAsPaid",
                            [id],
                            function(resp){
                                if(resp.status=="ok")
                                    window.location.reload();
                                else
                                    utils.alertEndpointResponse(resp);
                            }
                        );
                    });
                }
            );

            $page.find(scope+"__finance-accept").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    var formData = $form.getValues();
                    if(formData.finance_project_ok==1 && formData.finance_task_ok==1){
                        var $button = $(this);
                        swal({
                            title: "¿Aprobar factura?",
                            text: "Se aprobará el apartado financiero de esta factura",
                            type: "info",
                            showCancelButton: true,
                            confirmButtonColor: "#00bb00",
                            confirmButtonText: "Aprobar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: false,
                            closeOnCancel: true,
                            showLoaderOnConfirm: true
                        }, function(isConfirm){
                            if(isConfirm){
                                $button.prop("disabled",true);
                                gvf.endpoint(
                                    "controller\\cp\\supInvoiceDetailController::setFinanceApproval",
                                    [id,true,formData],
                                    function(resp){
                                        if(resp.status=="ok")
                                            window.location.reload();
                                        else
                                            utils.alertEndpointResponse(resp);
                                    }
                                );
                            }
                        });
                    }else
                        utils.alertEndpointResponse("Para aprobar el apartado financiero deben estar chequeados proyecto y tarea");
                }
            );

            $page.find(scope+"__finance-reject").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    var formData = $form.getValues();
                    var $button = $(this);
                    swal({
                        title: "¿Rechazar factura?",
                        text: "Se rechazará el apartado financiero de esta factura",
                        type: "input",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Rechazar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        showLoaderOnConfirm: true,
                        inputPlaceholder: "Motivo del rechazo"
                    }, function(inputValue){
                        if(inputValue != null){
                            if (inputValue === "") {
                                swal.showInputError("Debes incluir algo en el motivo del rechazo");
                                return false
                            }
                            $button.prop("disabled",true);
                            gvf.endpoint(
                                "controller\\cp\\supInvoiceDetailController::setFinanceApproval",
                                [id,false,formData,inputValue],
                                function(resp){
                                    if(resp.status=="ok")
                                        window.location.reload();
                                    else
                                        utils.alertEndpointResponse(resp);
                                }
                            );
                        }
                    });
                }
            );

            $page.find(scope+"__technical-accept").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    var formData = $form.getValues();
                    if(formData.technical_amount_ok==1 && formData.technical_execution_ok==1) {
                        var $button = $(this);
                        swal({
                            title: "¿Aprobar factura?",
                            text: "Se aprobará el apartado técnico de esta factura",
                            type: "info",
                            showCancelButton: true,
                            confirmButtonColor: "#00bb00",
                            confirmButtonText: "Aprobar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: false,
                            closeOnCancel: true,
                            showLoaderOnConfirm: true
                        }, function (isConfirm) {
                            if (isConfirm) {
                                $button.prop("disabled", true);
                                gvf.endpoint(
                                    "controller\\cp\\supInvoiceDetailController::setTechnicalApproval",
                                    [id, true, $form.getValues()],
                                    function (resp) {
                                        if (resp.status == "ok")
                                            window.location.reload();
                                        else
                                            utils.alertEndpointResponse(resp);
                                    }
                                );
                            }
                        });
                    }else
                        utils.alertEndpointResponse("Para aprobar el apartado técnico deben estar chequeados importe y ejecución");
                }
            );

            $page.find(scope+"__technical-reject").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    var $button = $(this);
                    swal({
                        title: "¿Rechazar factura?",
                        text: "Se rechazará el apartado técnico de esta factura",
                        type: "input",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Rechazar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        showLoaderOnConfirm: true,
                        inputPlaceholder: "Motivo del rechazo"
                    }, function(inputValue){
                       if(inputValue != null){
                            if (inputValue === "") {
                                swal.showInputError("Debes incluir algo en el motivo del rechazo");
                                return false
                            }
                            $button.prop("disabled",true);
                            gvf.endpoint(
                                "controller\\cp\\supInvoiceDetailController::setTechnicalApproval",
                                [id,false,$form.getValues(),inputValue],
                                function(resp){
                                    if(resp.status=="ok")
                                        window.location.reload();
                                    else
                                        utils.alertEndpointResponse(resp);
                                }
                            );
                        }
                    });
                }
            );

            var $technicalValidationToggle = $page.find("[name=finance_require_technical_check]");
            if($technicalValidationToggle.length>0){
                $technicalValidationToggle.change(
                    function(){
                        var active = this.checked;
                        if(active){
                            $page.find("[id=div_technical_validation_enabled]").removeClass("invisible");
                            $page.find("[id=div_technical_validation_enabled]").addClass("visible");
                        }
                        else{
                            $page.find("[id=div_technical_validation_enabled]").removeClass("visible");
                            $page.find("[id=div_technical_validation_enabled]").addClass("invisible");
                        }
                    }
                );
            }

            $page.find(scope+"__button-invoice").click(
                function(ev){
                    ev.preventDefault();
                    var $temp = $("<input>");
                    $("body").append($temp);
                    $temp.val($page.find(scope+"__url-invoice").text()).select();
                    document.execCommand("copy");
                    $temp.remove();
                }
            );

            $page.find(scope+"__change-status").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    swal({
                        title: "¿Cambiar estado a Enviada?",
                        text: "Se borrarán los datos que se han añadido posteriores a ese estado de esta factura",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#00bb00",
                        confirmButtonText: "Aceptar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        showLoaderOnConfirm: true
                    }, function(isConfirm){
                        if(isConfirm){
                            gvf.endpoint(
                                "controller\\cp\\supInvoiceDetailController::resetStatus",
                                [id],
                                function(resp){
                                    if(resp.status=="ok")
                                        window.location.reload();
                                    else
                                        utils.alertEndpointResponse(resp);
                                }
                            );
                        }
                    });
                }
            );



            $page.find("[data-name='project_task_ids']").on('formField:changeVal',(function(ev){
                var projectTaskIds = $page.find("[name='project_task_ids']").val();
                var orderIds = $page.find("[name='orders']").val();
                gvf.endpoint(
                    "controller\\cp\\supInvoiceDetailController::getDeliveryNotes",
                    [projectTaskIds, orderIds],
                    function(resp){
                        if(resp.status=="ok"){
                            $page.find("[data-name='delivery_notes']").formFieldSetVal(resp.deliveryNoteIds);
                        }
                    }
                );
            }));

		}
	);
}();

