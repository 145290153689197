var cusDocumentDetailPage = function(){
    var scope = ".js-cus-document-detail-page";

    ui.ready(
        scope,
        function($page){
            var defectsChecked = $(scope+"__switch").find("input[type='checkbox']").prop("checked");
            disableObservations(!defectsChecked);
            showCorrectFields();
            $page.find(scope+"__switch").click(
                function(ev){
                    $(this).find("input[type='checkbox']").on("change",function() {
                        var status = $(this).prop('checked');
                        //$(scope+"__switch").find("textarea[name='observations']").prop("disabled",!status);
                        disableObservations(!status);
                    });
                }
            );
            function disableObservations(status){
                $(scope+"__switch").find("textarea[name='observations']").prop("disabled",status)
            };
            $page.find("[name='cus_document_type_id']").on("change", function(){
               showCorrectFields();
            });

            function showCorrectFields(){
                var type = $page.find("[name='cus_document_type_id']").val();
                gvf.endpoint("controller\\cp\\cusDocumentDetailController::getFieldsByType", [type], function(resp){
                    for(var i = 0; i< resp.all.length; i++){
                        $page.find("[data-name='"+resp.all[i]+"']").hide();
                    }
                    for(var i = 0; i< resp.specific.length; i++){
                        $page.find("[data-name='"+resp.specific[i]+"']").show();
                    }
                });
            };

            $page.find(scope+"__approve-document").click(
                function(ev){
                   ev.preventDefault();
                    var documentId = $page.find("[name='id']").val();
                    swal({
                        title:"¿Desea aprobar el documento?",
                        text:"Se marcará el documento como aprobado/VB",
                        type:"info",
                        showCancelButton:true,
                        confirmButtonColor:"#00bb00",
                        confirmButtonText:"Aceptar",
                        cancelButtonText:"Cancelar",
                        closeOnConfirm:false,
                        closeOnCancel:true,
                        showLoaderOnConfirm:true
                    },function(isConfirm){
                        if(isConfirm){
                            gvf.endpoint(
                                $page.data("controller")+"::manageApprovationDocument",
                                [documentId, "approve"],
                                function(resp){
                                    if(resp.status=="ok"){
                                        utils.alertEndpointResponse(resp);
                                        gvf.delay(1500,function(){
                                            window.location.reload();
                                        });

                                    }else{
                                        utils.alertEndpointResponse(resp);
                                    }
                                }
                            )
                        }
                    });
                }
            );

            $page.find(scope+"__reject-document").click(
                function(ev){
                    ev.preventDefault();
                    var documentId = $page.find("[name='id']").val();
                    swal({
                        title:"¿Desea rechazar el documento?",
                        text:"Se marcará el documento como rechazado",
                        type:"warning",
                        showCancelButton:true,
                        confirmButtonColor:"#d50303",
                        confirmButtonText:"Aceptar",
                        cancelButtonText:"Cancelar",
                        closeOnConfirm:false,
                        closeOnCancel:true,
                        showLoaderOnConfirm:true
                    },function(isConfirm){
                        if(isConfirm){
                            gvf.endpoint(
                                $page.data("controller")+"::manageApprovationDocument",
                                [documentId, "reject"],
                                function(resp){
                                    if(resp.status=="ok"){
                                        utils.alertEndpointResponse(resp);
                                        gvf.delay(1500,function(){
                                            window.location.reload();
                                        });

                                    }else{
                                        utils.alertEndpointResponse(resp);
                                    }
                                }
                            )
                        }
                    });
                }
            );

            $page.find(scope+"__recovery").click(
                function(ev){
                    var documentId = $page.find("[name='id']").val();
                    swal({
                        title:"¿Deseas recuperar el documento actual?",
                        text:"Volverá a estar disponible en la sección Documentos",
                        type:"info",
                        showCancelButton:true,
                        confirmButtonColor:"#00bb00",
                        confirmButtonText:"Aceptar",
                        cancelButtonText:"Cancelar",
                        closeOnConfirm:false,
                        closeOnCancel:true,
                        showLoaderOnConfirm:true
                    },function(isConfirm){
                        if(isConfirm){
                            gvf.endpoint("controller\\cp\\cusDocumentDetailController::recoverDocument",[documentId],function(resp){
                                if(resp.status=="ok"){
                                    utils.alertEndpointResponse(resp);
                                    gvf.delay(1500,function(){
                                        window.location.reload();
                                    });

                                }else{
                                    utils.alertEndpointResponse(resp);
                                }
                            });
                        }
                    });
                }
            );

            $page.find(scope+"__duplicate-offer").click(function(ev){
                var $form = $page.find(".js-detail-page__main-form");
                var isOk = $form.checkRestrictions();
                scrollToError();
                if(isOk){
                    swal({
                        title:"¿Deseas duplicar esta oferta?",
                        text:"Se creará una nueva oferta con los datos de la actual pero con un nuevo Nº de Oferta y en estado 'Pendiente de realizar'. La oferta actual se conserva intacta.",
                        type:"info",
                        showCancelButton:true,
                        confirmButtonColor:"#00bb00",
                        confirmButtonText:"Aceptar",
                        cancelButtonText:"Cancelar",
                        closeOnConfirm:false,
                        closeOnCancel:true,
                        showLoaderOnConfirm:true
                    },function(isConfirm){
                        if(isConfirm){
                            gvf.endpoint("controller\\cp\\cusOfferDetailController::createSimilarOffer",[$form.getValues(),true],function(resp){
                                if(resp.status=="ok"){
                                    utils.alertEndpointResponse(resp);
                                    gvf.delay(1500,function(){
                                        window.location = resp.url;
                                    });
                                }else{
                                    utils.alertEndpointResponse(resp);
                                }
                            });
                        }
                    });
                }

            });

            $page.find(scope+"__new-version").click(function(ev){
                var $form = $page.find(".js-detail-page__main-form");
                var isOk = $form.checkRestrictions();
                if(isOk){
                    swal({
                        title:"¿Crear una nueva versión?",
                        text:"Se guardará una versión del documento actual que podrás consultar más adelante.",
                        type:"info",
                        showCancelButton:true,
                        confirmButtonColor:"#00bb00",
                        confirmButtonText:"Aceptar",
                        cancelButtonText:"Cancelar",
                        closeOnConfirm:false,
                        closeOnCancel:true,
                        showLoaderOnConfirm:true
                    },function(isConfirm){
                        if(isConfirm){
                            gvf.endpoint("controller\\cp\\cusDocumentDetailController::saveAsNewVersion",[$form.getValues()],function(resp){
                                if(resp.status=="ok"){
                                    utils.alertEndpointResponse(resp);
                                    gvf.delay(1500,function(){
                                        window.location = resp.url;
                                    });
                                }else{
                                    utils.alertEndpointResponse(resp);
                                }
                            });
                        }
                    });
                }

            });

        }
    );
}();


