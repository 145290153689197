ui.webModuleWorkerForm = function(){
	var scope = ".js-web-module-worker-form";

	ui.ready(
		scope,
		function($form){
            var $cacheFields = $form.find(scope+"__cache-fields");
            var $visibilityFields = $form.find(scope+"__visibility-fields");

            $form.find(scope+"__cache-button").click(
                function(ev){
                    ev.preventDefault();
                    $cacheFields.toggle();
                }
            );
            $form.find(scope+"__visibility-button").click(
                function(ev){
                    ev.preventDefault();
                    $visibilityFields.toggle();
                }
            );
		}
	);
}();