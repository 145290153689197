var statReportDetailPage = function(){
	var scope = ".js-stat-report-detail-page";

	function runReport($page,$form,$results){
	    if($results.data("updated")){
            return;
        }
        $results.html('<div class="cp-spinner"></div>');
        $results.addClass("is-loading");
        gvf.endpoint(
            $page.data("controller")+"::getReport",
            [$form.getValues()],
            function(response){
                $results.removeClass("is-loading");
                if(response.status=="ok"){
                    $results.data("updated",true);
                    $results.html(response.html);
                    ui.init($results);
                }else{
                    utils.alertEndpointResponse(response);
                }
            }
        );
    }

	ui.ready(
        scope,
		function($page){
            var $form = $page.find(".js-detail-page__main-form");
            var $results = $page.find(scope+"__results");

            if($results.is(":visible")){
                runReport($page,$form,$results);
            }

            $page.find("[href='#preview']").on(
                "shown.bs.tab",
                function(){
                    runReport($page,$form,$results);
                }
            );

            $form.find("[data-name='content.tables']").on(
                "formField:changeVal",
                function(){
                    $results.data("updated",false);
                }
            );
		}
	);
}();

