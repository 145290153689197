var cusOfferApprovalModal = function(){
    var scope = ".js-cus-offer-approval-modal";
    ui.ready(scope,function($page){
        console.log($page);
        var $form = $page.find(scope+"__form");
        $form.submit(function(ev){
            ev.preventDefault();
            var isOk = $form.checkRestrictions();
            if(isOk){
                page.showLoading();
                gvf.endpoint("controller\\cp\\cusOfferDetailController::submitSupplierRequest",[$form.getValues()],function(resp){
                    page.hideLoading();
                    if(resp.status=="ok"){
                        utils.alertEndpointResponse(resp);
                        setTimeout(function(){
                            window.location = resp.url;
                        },2000);
                    }else if(resp.validation){
                        $form.applyValidationResponse(resp.validation);
                    }else{
                        utils.alertEndpointResponse(resp);
                    }
                });
            }
        });

        $page.find("input[type='number']").on('change',(function(ev){
            var name = $(this).attr("name");
            var id = name.split("-");
            id = id[id.length-1];
            var discount = $page.find("[data-name='discount-"+id+"']").formFieldVal();
            var unitPrice = $page.find("[data-name='unit_price-"+id+"']").formFieldVal();
            var pvpPrice = unitPrice-(discount*unitPrice/100);
            $page.find("[data-name='price-"+id+"']").formFieldSetVal(pvpPrice);

        }));
    });
}();
