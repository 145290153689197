var projectTaskListPage = function(){
    var scope = ".js-project-task-list-page";
    ui.ready(scope,function($page){

        $page.find(scope+"__sync").off().click(function(ev){
            ev.preventDefault();
            swal({
                title:"¿Sincronizar datos?",
                text:"Esto llevará unos segundos",
                type:"info",
                showCancelButton:true,
                confirmButtonColor:"#00bb00",
                confirmButtonText:"Aceptar",
                cancelButtonText:"Cancelar",
                closeOnConfirm:false,
                closeOnCancel:true,
                showLoaderOnConfirm:true
            },function(isConfirm){
                if(isConfirm){
                    gvf.endpoint($page.data("controller")+"::syncDataManual",["ListaTareasProyecto",false],function(resp){
                        if(resp.status=="ok"){
                            window.location.reload();
                        }else{
                            utils.alertEndpointResponse(resp);
                        }
                    });
                }
            });
        });


    });
}();


