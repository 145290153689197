//form fields
ui.formFieldDate = function(){
    var scope = ".js-form-field-radio";

    ui.ready(
        scope,
        function($field){
            var radio = $field.find(scope+"__radio");
            radio.click(
                function(ev){
                    $field.trigger("formField:changeVal");
                }
            );

            $field.data(
                "funcGetVal",
                function(){
                    var values = [];
                    radio.filter(":checked").each(
                        function(){
                            values.push($(this).val());
                        }
                    );
                    return values;
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    radio.find("[value='"+val+"']").attr("checked","checked");
                    $field.trigger("formField:changeVal");
                }
            );
        }
    );
}();