var statDashboard = function(){
    var scope = ".js-stat-dashboard";
    var filteredIndexes=null;

    function isFilteredIndex($dashboard,dashboardIndex){
        if(filteredIndexes===null){
            var filters = $dashboard.closest("form").obtenerValores();
            filteredIndexes = {};
            for(var f in filters){
                if(f.indexOf("viFilter")===0){
                    for(var target in filters[f]){
                        var parts = filters[f][target].split(".");
                        filteredIndexes[parts[0]] = true;
                    }
                }
            }
        }
        return filteredIndexes["["+dashboardIndex+"]"]?true:false;
    }

    function updateArea($item,$dashboard){
        var $itemContent = $item.find(".grid-stack-item-content");
        $itemContent.html("<div class='grid-stack-item-label'>Cargando...</div>");
        var data = $item.data("dashboard");
        var $dashboardForm = $dashboard.closest(".js-form");
        var filters = {};
        if($dashboardForm.length>0){
            filters = $dashboardForm.getValues();
            delete filters.content;
            delete filters.id;
        }

        gvf.endpoint(
            "cp\\statDashboardDetailController::getDashboardAreaValue",
            [data,$item.data("dashboardIndex"),filters],
            function(resp){
                if(resp.status=="ok"){
                    var $new = $(resp.html);
                    if(data.anchor){ //select only anchor area
                        var selector = "[data-dashboard-anchor='"+data.anchor+"']";
                        var className = "grid-stack-item-table";
                        if(data.anchor.indexOf("c")>-1){
                            className = "grid-stack-item-chart";
                        }
                        $new.find(".grid-stack-item-body").addClass("js-stat-report-results").html($new.find(selector).prop("outerHTML")).addClass(className);
                    }
                    $itemContent.html($new.prop("outerHTML"));
                    ui.init($itemContent);
                    fitItems($item);
                }
            }
        );
    }

    function updateDashboardValue($dashboard){
        var items = [];
        $dashboard.find(".grid-stack-item").each(
            function(){
                var data = $(this).data("dashboard");
                //update current dimensions/positions
                data.x = $(this).attr("data-gs-x");
                data.y = $(this).attr("data-gs-y");
                data.width = $(this).attr("data-gs-width");
                data.height = $(this).attr("data-gs-height");
                $(this).data("dashboard",data);
                items.push(data);
            }
        );
        $dashboard.find(scope+"__data").val(JSON.stringify(items));
    }

    function fitItems($container,delayed){
        setTimeout(
            function(){
                $container.find(".grid-stack-item-value").each(
                    function(){
                        textFit(this,{alignVert: true,alignHoriz: true});
                    }
                );
                $container.find(".grid-stack-item-value-comp").each(
                    function(){
                        textFit(this,{alignHoriz: true,multiLine: false, maxFontSize: 14});
                    }
                );
                $container.find(".js-stat-report-results__ht-container").each(
                    function(){
                        if($(this).data("htinstance")){
                            var height = $(this).closest(".grid-stack-item").height()-parseInt($(this).closest(".grid-stack-item-body").css("padding-top"));
                            $(this).data("htinstance").updateSettings({"height":height});
                            $(this).data("htinstance").render();
                        }
                    }
                );
                $container.find(".js-stat-report-results__chart-container").each(
                    function(){
                        if($(this).data("chartinstance")){
                            var height = $(this).closest(".grid-stack-item").height()-parseInt($(this).closest(".grid-stack-item-body").css("padding-top"));
                            var $legend = $(this).find(".ct-legend");
                            if($legend.length>0){
                                height -= $legend.height();
                            }
                            $(this).css("height",height);
                            $(this).data("chartinstance").update();
                        }
                    }
                );
            },
            delayed?300:10
        );
    }

    ui.ready(
        scope,
        function($dashboard){
            var $dashboardContainer = $dashboard.find(scope+"__container");
            var $form = $dashboard.closest(".js-form");
            var $itemWorkerField = $dashboard.find("[data-name=item_worker]");
            var gridstack = null;

            function initDashboard(){
                gridstack = GridStack.init(
                    {
                        alwaysShowResizeHandle: $dashboard.hasClass("is-editable"),
                        resizable: {
                            handles: 'e, se, s, sw, w'
                        },
                        removable: true,
                        removeTimeout: 100,
                        disableResize: !$dashboard.hasClass("is-editable"),
                        disableDrag: !$dashboard.hasClass("is-editable"),
                        verticalMargin: 10
                    }
                );
                $dashboardContainer.data("gridstack");

                var val = $dashboard.find(scope+"__data").val();
                if(val){
                    var data = JSON.parse(val);
                    for(var i in data){
                        gridstack.addWidget("<div class='grid-stack-item'><div class='grid-stack-item-content'><div class='grid-stack-item-label'>Cargando...</div></div></div>",data[i]);
                        var $item = $dashboard.find(".grid-stack-item").last();
                        $item.data("dashboard",data[i]);
                        $item.data("dashboardIndex",i);
                        updateArea($item,$dashboard);
                    }
                }

                $dashboardContainer.on('change', function(event, items) {
                    fitItems($dashboard,true);
                    updateDashboardValue($dashboard);
                });
            }

            $dashboard.find(scope+"__add").click(
                function(ev){
                    ev.preventDefault();
                    var data = {height:3,width:6};
                    gridstack.addWidget("<div class='grid-stack-item'><div class='grid-stack-item-content'><div class='grid-stack-item-label'>Click para configurar</div></div></div>",data);
                    $(".grid-stack-item").last().data("dashboard",data);
                }
            );

            $dashboard.find(scope+"__remove").click(
                function(){
                    alert("Arrastra un elemento hacia aquí para eliminarlo");
                }
            );

            $dashboardContainer.click(
                function(ev){
                    var $item = $(ev.target).closest(".grid-stack-item");
                    if($item.data("dashboard")){
                        if($dashboard.hasClass("is-editable")){
                            $itemWorkerField.unbind().on(
                                "formField:changeVal",
                                function(ev){
                                    if(ev.target===$itemWorkerField.get(0)){
                                        $item.data("dashboard",$itemWorkerField.formFieldVal());
                                        updateArea($item,$dashboard);
                                        updateDashboardValue($dashboard);
                                    }
                                }
                            );
                            $itemWorkerField.openModal($item.data("dashboard"));
                        }
                    }
                }
            );

            $form.find(scope+"__filter-submit").click(
                function(ev){
                    ev.preventDefault();
                    $(".grid-stack-item").each(
                        function(){
                            if(isFilteredIndex($dashboard,$(this).data("dashboardIndex"))){ //only update if needed
                                updateArea($(this),$dashboard);
                            }
                        }
                    );
                }
            );

            setTimeout(initDashboard,1000);
        }
    );
}();
