var customerListPage = function(){
    var scope = ".js-customer-list-page";
    ui.ready(scope,function($page){
        $page.find(scope+"__customer-ellipsis").unbind().click(function(event){
            $targetNode = $(event.target);
            if($targetNode.data("ellipsis")==true){
                event.preventDefault();
                event.stopPropagation();
                toggleEllipsis($targetNode);
            }
        });
        function toggleEllipsis($targetNode){
            if(!$targetNode.hasClass("show")){
                $targetNode.toggleClass("show");
            }else{
                if(!$targetNode.is("a")){
                    $targetNode.toggleClass("show");
                }
            }
        }
    //end ready
    });
}();