//form fields
ui.formFieldDate = function(){
	var scope = ".js-form-field-checks";

	ui.ready(
		scope,
		function($field){
			var $checks = $field.find(scope+"__check");
            $checks.click(
                function(ev){
                    $field.trigger("formField:changeVal");
                }
            );

            $field.data(
                "funcGetVal",
                function(){
                    var values = [];
                    $checks.filter(":checked").each(
                        function(){
                            values.push($(this).val());
                        }
                    );
                    return values;
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    //todo set val
                }
            );
		}
	);
}();