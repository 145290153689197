var simalgaDocumentDetailPage = function(){
    var scope = ".js-simalga-document-type-detail-page";
    ui.ready(scope,function($page){

        $page.find("[data-name='created']").on('formField:changeVal',(function(ev){
            ev.preventDefault();
            var fileTypeId = $page.find("[data-name='sup_document_type_id']").formFieldVal();
            var created = $page.find("[data-name='created']").formFieldVal();

            if(created){
                gvf.endpoint("controller\\cp\\simalgaDocumentDetailController::getExpirationDate",[fileTypeId,created],function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='expiration_date']").formFieldSetVal(resp.expiration_date);
                        $page.find("[name='expiration_date']").val(resp.expiration_date);
                    }
                })
            }
        }));
        console.log($page.find('select[name="sup_document_type_id"]'));
        $page.find('select[name="sup_document_type_id"]').change(function(ev){
            ev.preventDefault();
            var fileTypeId = $page.find("[data-name='sup_document_type_id']").formFieldVal();
            var created = $page.find("[data-name='created']").formFieldVal();
            if(created){
                gvf.endpoint("controller\\cp\\simalgaDocumentDetailController::getExpirationDate",[fileTypeId,created],function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='expiration_date']").formFieldSetVal(resp.expiration_date);
                        $page.find("[name='expiration_date']").val(resp.expiration_date);
                    }
                });
            }
        });

    });
}();
