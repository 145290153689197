var simpleListPage = function(){
	var scope = ".js-simple-list-page";
	var $map;
	var map;
	var $listPage;

	function getItemId($item){
        return $item.find("a").eq(0).attr("href").substring(1);
    }

    function updateSelectAllState($page){
	    $page.find(scope+"__add-selected-items").prop("disabled",$page.find(scope+"__tr.is-selected").length==0);
    }


    function gmReady(){


        var search = $listPage.data("searchparams");
        if(search.length == 0){
            search = "noSearch";
        }
        gvf.endpoint(
            $listPage.data("controller")+"::getMapFacilities",
            [search],
            async function(resp){
                console.log(resp)
                if(resp.status=="ok"){
                    var centerLat = 40.394500;
                    var centerLng = -3.694428;
                    var zoom = 6;
                    if(resp.mapData.centerLat){
                        centerLat = resp.mapData.centerLat;
                    }
                    if(resp.mapData.ceterLng){
                        centerLng = resp.mapData.centerLng;
                    }
                    if(resp.mapData.zoom){
                        zoom = resp.mapData.zoom;
                    }
                    const {Map,InfoWindow} = await google.maps.importLibrary("maps");

                    const map = new google.maps.Map(
                        $map.get(0),
                        {
                            zoom: zoom,
                            center: new google.maps.LatLng(centerLat,centerLng),
                            mapTypeId: "roadmap",
                            mapId: "DEMO_MAP_ID"
                        }
                    );

                    for (var i = 0; i < resp.markers.length; i++) {
                        (async function () {
                            var item = resp.markers[i];
                            var position = { lat: item.lat, lng: item.lng };

                            const { PinElement } = await google.maps.importLibrary("marker");

                            const pinBackground = new PinElement({
                                background: "#318def",
                                borderColor: "#034285",
                                glyphColor: "white"
                            });

                            const marker = new google.maps.marker.AdvancedMarkerElement({
                                map,
                                position: position,
                                title: item.title,
                                content: pinBackground.element
                            });

                            // Make Clickable
                            const infoWindow = new InfoWindow();

                            marker.addListener("click", ({ domEvent, latLng }) => {
                                const { target } = domEvent;

                                infoWindow.close();
                                infoWindow.setContent("<a href='" + item.link + "'>" + item.title + "</a>");
                                infoWindow.open(marker.map, marker);
                            });
                        })();
                    }

                }

            });


        /*var position = { lat: 40.394500, lng: -3.694428 };

        const marker = new google.maps.marker.AdvancedMarkerElement({
            map,
            position: position,
            title: 'Uluru',
        });

        var position = { lat: 40.394500, lng: -2.694428 };

        const marker2 = new google.maps.marker.AdvancedMarkerElement({
            map,
            position: position,
            title: '2',
        });*/

    }


    function gmReady2(){
        console.log("hola");
        map = new google.maps.Map(
            $map.get(0),
            {
                zoom: 6,
                center: new google.maps.LatLng(40.394500, -3.694428),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            }
        );
        map.markers = [];
        map.getViewParams = function(){
            var bounds = this.getBounds();
            var obj = {};
            obj.zoom = this.getZoom();
            if(bounds){
                var sw = bounds.getSouthWest();
                var ne = bounds.getNorthEast();
                obj.xmin = sw.lng();
                obj.ymin = sw.lat();
                obj.xmax = ne.lng();
                obj.ymax = ne.lat();
            }else{
                obj.xmin = -10;
                obj.ymin = 35;
                obj.xmax = 10;
                obj.ymax = 50;
            }
            return obj;
        };

        map.createNumberedIcon = function(value,max,min){
            var level = 0;
            if(max>min){
                level = Math.round((value-min)/(max-min)*15);
            }
            var size="1.1";
            var sizeFont="11";
            var iSize=new google.maps.Size(41,73);
            var iPoint=new google.maps.Point(20,73);
            if(value<6){
                size="0.8";
                sizeFont="9";
                iSize=new google.maps.Size(31,54);
                iPoint=new google.maps.Point(15,54);
            }else if(value<10){
                size="0.9";
                sizeFont="10";
                iSize=new google.maps.Size(35,61);
                iPoint=new google.maps.Point(17,61);
            }

            var colors = ["FFFFFF","FFEFEF","FFDFDF","FFCFCF","FFBFBF","FFAFAF","FF9F9F","FF8F8F","FF7F7F","FF6F6F","FF5F5F","FF5F5F","FF4F4F","FF4F4F","FF3F3F","FF3F3F"];
            var url = "https://chart.googleapis.com/chart?chst=d_map_spin&chld="+size+"|0|"+colors[level]+"|"+sizeFont+"|b|"+Math.round(value);
            return new google.maps.MarkerImage(url,iSize,null,iPoint);
        }

        map.removeMarkers = function(){
            for(var i=0;i<this.markers.length;i++){
                this.markers[i].setMap(null);
            }
            this.markers = [];
        }

        map.addMarker = function(marker){
            marker.setMap(this);
            this.markers.push(marker);
        }

        map.updateItemsDelayed = function(){
            gvf.delay(500,this.updateItems);
        };

        map.updateItems = function(){
            var $modal = $map.closest(".js-form-field-model-selector__modal");
            gvf.endpoint(
                $listPage.data("controller")+"::getMapItems",
                [$listPage.data("modelname"),map.getViewParams(),$listPage.data("searchparams"),$modal.length>0?$modal.data("fieldData"):null],
                function(resp){
                    if(resp.status=="ok"){
                        var max=-1;
                        var min=-1;
                        for(var i=0;i<resp.items.length;i++){
                            resp.items[i].v = parseFloat(resp.items[i].v);
                            if(max==-1 || resp.items[i].v>max)
                                max = resp.items[i].v;
                            if(min==-1 || resp.items[i].v<min)
                                min = resp.items[i].v;
                        }

                        map.removeMarkers();

                        for(var i=0;i<resp.items.length;i++){
                            var item = resp.items[i];
                            var marker = new google.maps.marker.AdvancedMarkerElement(
                                {
                                    position:new google.maps.LatLng(item.y,item.x),
                                    title: item.d
                                }
                            );
                            marker.item = item;

                            google.maps.event.addListener(
                                marker,
                                "click",
                                function(){
                                    var thisMarker = this;
                                    if(this.item.v>1){
                                        map.setCenter(this.getPosition());
                                        map.setZoom(map.getZoom()+1);
                                    }else{
                                        if($modal.length>0){
                                            $modal.data("addValue")(this.item.id);
                                        }else{
                                            gvf.endpoint(
                                                $listPage.data("controller")+"::getMapItemDetail",
                                                [$listPage.data("modelname"),this.item.id],
                                                function(resp){
                                                    if(resp.status=="ok"){
                                                        var info = new google.maps.InfoWindow({
                                                            content: resp.html
                                                        });
                                                        info.open(map,thisMarker);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                            );
                            map.addMarker(marker);
                        }
                    }
                }
            );
        };
        google.maps.event.addListener(map,"center_changed",map.updateItemsDelayed);
        google.maps.event.addListener(map,"zoom_changed",map.updateItemsDelayed);
        map.updateItems();
    }

    ui.ready(
        scope,
		function($page){
            $listPage = $page;
            var currentPage=0;
            var $modal = $page.closest(".js-form-field-model-selector__modal");
            var $moreItemsButton = $page.find(scope+"__more-items");
            var $table = $page.find(scope+"__table-container");
            var $showMapButton = $page.find(scope+"__show-map");
            var $hideMapButton = $page.find(scope+"__hide-map");

            $moreItemsButton.click(
            	function(ev){
            		ev.preventDefault();
                    currentPage++;
                    gvf.endpoint(
                        $page.data("controller")+"::renderListContent",
                        [$page.data("modelname"),$page.data("searchparams"),currentPage,null,$modal.length>0?$modal.data("fieldData"):null,$modal.length>0?"model-selector":""],
                        function(resp){
                            if(resp.status=="ok"){
                                var $tBody = $($(resp.html).find(scope+"__tbody").html());
                                $page.find(scope+"__tbody").append($tBody);
                                ui.init($tBody);
                                $moreItemsButton.toggle(resp.moreItems);
                                if($modal.length>0){
                                    $tBody.find('a[href^="#"]').click(
                                        function(ev){
                                            ev.preventDefault();
                                            $modal.modal("hide");
                                            $modal.data("addValue")($(this).attr("href").substring(1));
                                        }
                                    );
                                }
                            }
                        }
                    );
				}
			);

            $page.find(scope+"__tr").click(
                function(ev){
                    if($modal.length>0){
                        $modal.data("addValue")(getItemId($(this)));
                    }else
                        window.location = $(this).find("a").eq(0).attr("href");
                }
            );

            $page.find(scope+"__select-all-items").click(
                function(){
                    $page.find(scope+"__select-item-input").each(
                        function(){
                            this.checked = !this.checked;
                            $(this).closest(scope+"__tr").toggleClass("is-selected",this.checked);
                        }
                    );
                    updateSelectAllState($page);
                }
            );

            $page.find(scope+"__select-item").click(
                function(ev){
                    ev.stopPropagation();
                    $(this).closest(scope+"__tr").toggleClass("is-selected",$(this).find(scope+"__select-item-input").get(0).checked);
                    updateSelectAllState($page);
                }
            );

            $page.find(scope+"__add-selected-items").click(
                function(){
                    var ids = [];
                    $page.find(scope+"__tr.is-selected").each(
                        function(){
                            ids.push(getItemId($(this)));
                        }
                    );

                    if(ids.length>0){
                        $modal.data("addValue")(ids);
                    }
                }
            );

            $page.find(scope+"__create").click(
                function(ev){
                    if($modal.length>0){
                        ev.preventDefault();
                        detailPage.openModal(
                            $listPage.data("modelname"),
                            null,
                            true,
                            null,
                            function(id){
                                if($modal.data("refreshModal"))
                                    $modal.data("refreshModal")();
                                else
                                    $modal.data("addValue")(id);
                            },
                            $modal.data("fieldData")
                        );
                    }
                }
            );

            $page.find(scope+"__edit-item,"+scope+"__view-item").click(
                function(ev){
                    if($modal.length>0){
                        var $item = $(this).closest(scope+"__tr");
                        ev.preventDefault();
                        ev.stopPropagation();
                        detailPage.openModal(
                            $listPage.data("modelname"),
                            $item.data("id"),
                            $(this).is(scope+"__edit-item"),
                            null,
                            function(id){
                                if($modal.data("refreshModal"))
                                    $modal.data("refreshModal")();
                            },
                            $modal.data("fieldData"),
                            function(){
                                $item.remove();
                            }
                        );
                    }
                }
            );

            $showMapButton.click(
                function(){
                    if(!$map || !$table.hasClass("has-map")){
                        $map = $("<div />").addClass("cp-simple-list-page__map");
                        $table.hide().after($map);
                        $table.addClass("has-map");
                    }else{
                        $table.hide();
                        $map.show();
                    }
                    if(!window.google || !window.google.maps){
                        //gvf.loadJsScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA_XQa2EBfxeb5sE-zKHALERY1BiIBskg8&libraries=marker,&callback=simpleListPage.gmReady");
                        gvf.loadJsScript("https://maps.googleapis.com/maps/api/js?key="+$page.data("gmkey")+"&libraries=marker&callback=simpleListPage.gmReady");

                    } else{
                        gmReady();
                    }
                    $showMapButton.hide();
                    $hideMapButton.show();
                }
            );

            $hideMapButton.click(
                function(){
                    if($map){
                        $map.hide();
                        $table.show();
                        $hideMapButton.hide();
                        $showMapButton.show();
                    }
                }
            ).hide();
		}
	);

	return {
        gmReady:gmReady
    };
}();

