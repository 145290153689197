//form fields
ui.workerEditionForm = function(){
	var scope = ".js-worker-edit";

	ui.ready(
		scope,
		function($edit){
            var $modal = $edit.closest(".js-form-field-custom-object__modal");
            $modal.toggleClass("has-only-menu",$edit.find("form").length==0);
		    $edit.find(scope+"__link").click(
                function(ev){
                    ev.preventDefault();
                    $edit.toggleClass("is-menu-hidden",$edit.data("keep-menu")!=1);
                    var data = {id:$(this).data("id")};
                    $edit.find(scope+"__link").removeClass("is-selected");
                    $(this).addClass("is-selected");
                    var fieldData = $edit.data("field");
                    var $formC = $edit.find(scope+"__form-container");
                    fieldData.from_menu = true;
                    $formC.html("");
                    gvf.endpoint(
                        "controller\\cp\\workerController::renderCustomObjectEditionForm",
                        [data,fieldData],
                        function(resp){
                            if(resp.status=="ok"){
                                $formC.html(resp.html);
                                var $innerForm = $formC.find('.js-custom-object-edit__form');
                                $innerForm.submit(
                                    function(ev){
                                        ev.preventDefault();
                                        var v = $(this).getValues();
                                        if($(this).checkRestrictions()){
                                            gvf.endpoint(
                                                "controller\\cp\\workerController::submitEditionForm",
                                                [v,fieldData],
                                                function(resp){
                                                    if(resp.status=="ok"){
                                                        if(fieldData.multiple){
                                                            $modal.data("sourceField").addValue(resp.data,false,fieldData.editPosition);
                                                        }else{
                                                            $modal.data("sourceField").setValue(resp.data);
                                                        }
                                                    }
                                                }
                                            );
                                        }
                                    }
                                );
                                ui.init($formC);

                                if($innerForm.find(".js-form-field:visible").length>0){ //has visible fields
                                    $modal.removeClass("has-only-menu");
                                }else{ //auto submit form
                                    $innerForm.submit();
                                }
                            }
                        }
                    );
                }
            );
		}
	);
}();