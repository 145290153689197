var cusOfferDetailSupplierRequestPage = function(){
    var scope = ".js-cus-offer-detail-supplier-request-list-page";
    ui.ready(scope,function($page){
        $page.find(".cus-offer-request__send-remind").click(function(ev){
            ev.stopPropagation();
            var offerRequest =ev.target.id;
            swal({
                title:"¿Enviar recordatorio manualmente?",
                text:"Enviarás un aviso manual al proveedor para pedirle que conteste a la oferta. Esto no afecta a los avisos automáticos que envía la aplicación.",
                type:"warning",
                showCancelButton:true,
                confirmButtonColor:"#00bb00",
                confirmButtonText:"Aceptar",
                cancelButtonText:"Cancelar",
                closeOnConfirm:false,
                closeOnCancel:true,
                showLoaderOnConfirm:true
            },function(isConfirm){
                if(isConfirm){
                   gvf.endpoint(
                       "controller\\cp\\cusOfferDetailSupplierRequestListController::sendManualRemind",
                       [offerRequest],
                       function(resp){
                           utils.alertEndpointResponse(resp);
                       }
                   );

                }
            });
        });


    });
}();