var page = function(){
    var scope = ".js-page";
    var zIndex = 1050;

    function showLoading(){
        var $page = $(scope);
        $page.addClass("is-starting-loading");
        gvf.delay(
            500,
            function(){
                if($page.hasClass("is-starting-loading"))
                    $page.addClass("is-loading");
            }
        );
    }

    function hideLoading(){
        $(scope).removeClass("is-starting-loading is-loading");
    }

    ui.ready(
        scope,
        function($page){
            if($page.data("popup-message")){
                swal(
                    {
                        type: "info",
                        title: "",
                        text: $page.data("popup-message")
                    }
                );
            }

            $page.find(scope+"__logout").click(
                function(ev){
                    ev.preventDefault();
                    showLoading();
                    gvf.endpoint(
                        "\\controller\\cp\\cpController::logout",
                        [],
                        function(response){
                            hideLoading();
                            if(response.status=="ok")
                                window.location = response.url;
                            else
                                utils.alertEndpointResponse(response);
                        }
                    );
                }
            );

            $page.find(scope+"__supplier .js-form-field").on(
                "formField:changeVal",
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                        "\\controller\\cp\\cpController::setSupplier",
                        [$(this).formFieldVal()],
                        function(response){
                            if(response.status=="ok"){
                                window.location = "/";
                            }else
                                utils.alertEndpointResponse(response);
                        }
                    );
                }
            );

            $page.find(scope+"__startImpersonate .js-form-field").on(
                "formField:changeVal",
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                        "\\controller\\cp\\cpController::impersonateUser",
                        [$(this).formFieldVal()],
                        function(response){
                            if(response.status=="ok"){
                                window.location = "/";
                            }else
                                utils.alertEndpointResponse(response);
                        }
                    );
                }
            );

            $page.find(scope+"__endImpersonate").click(
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                        "\\controller\\cp\\cpController::endImpersonate",
                         [],
                        function(response){
                            if(response.status=="ok"){
                                window.location = "/";
                            } else {
                                utils.alertEndpointResponse(response);
                            }
                        }
                    )
                }
            )

            $page.find(".facility-section").ready(function(){
                gvf.endpoint(
                    "\\controller\\cp\\cusFacilityItemListController::getFacilityImage",
                    [0],
                    function(resp){
                        $("#facility_preview").empty();
                        $("#facility_preview").hide();
                        $("#facility_preview").append(resp);
                        $("#facility_preview").show(300);
                    }
                );
            });

            $page.find(".facility-section").click(function(){
                var activeItem = $page.find(".facility-name").closest(".active");
               if(activeItem.length == 0){
                   $("#facility_preview").empty();
                   $("#facility_preview").hide(300);

               } else {
                   var id= activeItem[0].value;
                   gvf.endpoint(
                       "\\controller\\cp\\cusFacilityItemListController::getFacilityImage",
                       [id],
                       function(resp){
                           $("#facility_preview").empty();
                           $("#facility_preview").hide();
                           $("#facility_preview").append(resp);
                           $("#facility_preview").show(300);
                       }
                   );
               }
            });

            //Nested modals scroll fix
            $(document).on(
                'hide.bs.modal',
                '.modal',
                function(){
                    zIndex--;
                    if($('.modal:visible').length>0){
                        $(document.body).addClass('modal-open');
                    }
                    $(".modal-backdrop").css("z-index",zIndex-1);
                }
            );
            $(document).on(
                'show.bs.modal',
                '.modal',
                function(ev){
                    zIndex++;
                    $(ev.target).css("z-index",zIndex);
                }
            );
            $(document).on(
                'shown.bs.modal',
                '.modal',
                function(ev){
                    $(".modal-backdrop").css("z-index",zIndex-1);
                }
            );
        }
    );

    return {
        showLoading:showLoading,
        hideLoading:hideLoading
    };
}();