var statReportResults = function(){
	var scope = ".js-stat-report-results";

	function createLineChart(container,data,options){
        options.lineSmooth = Chartist.Interpolation.simple({divisor: 2});
        options.plugins = [
            Chartist.plugins.legend({
                legendNames: options.legendNames,
                position: "bottom"
            })
        ];
        if(data.series && data.series.length>0){
            options.axisX = {
                labelInterpolationFnc: function(value, index){
                    return index % Math.round(data.series[0].data.length/7)  === 0 ? value : null;
                }
            };
        }
        var chart = new Chartist.Line(container,data,options);

        // Let's put a sequence number aside so we can use it in the event callbacks
        var seq = 0,
            delays = 40,
            durations = 500;

        // Once the chart is fully created we reset the sequence
        chart.on('created', function() {
            seq = 0;
        });

        // On each drawn element by Chartist we use the Chartist.Svg API to trigger SMIL animations
        chart.on('draw', function(data) {
            seq++;
            if(chart.animationDone){
                return;
            }

            if(data.type === 'line') {
                // If the drawn element is a line we do a simple opacity fade in. This could also be achieved using CSS3 animations.
                var emitter = Chartist.EventEmitter();
                emitter.addEventHandler('animationEnd', function(){
                    chart.animationDone = true;
                });
                data.element.animate({
                    opacity: {
                        // The delay when we like to start the animation
                        begin: seq * delays + 1000,
                        // Duration of the animation
                        dur: durations,
                        // The value where the animation should start
                        from: 0,
                        // The value where it should end
                        to: 1
                    }
                },true,emitter);
            } else if(data.type === 'label' && data.axis === 'x') {
                data.element.animate({
                    y: {
                        begin: seq * delays,
                        dur: durations,
                        from: data.y + 100,
                        to: data.y,
                        // We can specify an easing function from Chartist.Svg.Easing
                        easing: 'easeOutQuart'
                    }
                });
            } else if(data.type === 'label' && data.axis === 'y') {
                data.element.animate({
                    x: {
                        begin: seq * delays,
                        dur: durations,
                        from: data.x - 100,
                        to: data.x,
                        easing: 'easeOutQuart'
                    }
                });
            } else if(data.type === 'point') {
                data.element.animate({
                    x1: {
                        begin: seq * delays,
                        dur: durations,
                        from: data.x - 10,
                        to: data.x,
                        easing: 'easeOutQuart'
                    },
                    x2: {
                        begin: seq * delays,
                        dur: durations,
                        from: data.x - 10,
                        to: data.x,
                        easing: 'easeOutQuart'
                    },
                    opacity: {
                        begin: seq * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'easeOutQuart'
                    }
                });
            } else if(data.type === 'grid') {
                // Using data.axis we get x or y which we can use to construct our animation definition objects
                var pos1Animation = {
                    begin: seq * delays,
                    dur: durations,
                    from: data[data.axis.units.pos + '1'] - 30,
                    to: data[data.axis.units.pos + '1'],
                    easing: 'easeOutQuart'
                };

                var pos2Animation = {
                    begin: seq * delays,
                    dur: durations,
                    from: data[data.axis.units.pos + '2'] - 100,
                    to: data[data.axis.units.pos + '2'],
                    easing: 'easeOutQuart'
                };

                var animations = {};
                animations[data.axis.units.pos + '1'] = pos1Animation;
                animations[data.axis.units.pos + '2'] = pos2Animation;
                animations['opacity'] = {
                    begin: seq * delays,
                    dur: durations,
                    from: 0,
                    to: 1,
                    easing: 'easeOutQuart'
                };

                data.element.animate(animations);
            }
        });
        return chart;
    }

    function createBarChart(container,data,options){
        var chart = new Chartist.Bar(container,data,options);
        return chart;
    }

    function createPieChart(container,data,options){
        var chart = new Chartist.Pie(container,data,options);
        chart.on('draw', function (data) {
            if (data.type === 'slice') {
                // Get the total path length in order to use for dash array animation
                var pathLength = data.element._node.getTotalLength();

                // Set a dasharray that matches the path length as prerequisite to animate dashoffset
                data.element.attr({
                    'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
                });

                // Create animation definition while also assigning an ID to the animation for later sync usage
                var animationDefinition = {
                    'stroke-dashoffset': {
                        id: 'anim' + data.index,
                        dur: 500 * data.value / data.totalDataSum,
                        from: -pathLength + 'px',
                        to: '0px',
                        // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
                        fill: 'freeze'
                    }
                };

                // If this was not the first slice, we need to time the animation so that it uses the end sync event of the previous animation
                if (data.index !== 0) {
                    animationDefinition['stroke-dashoffset'].begin = 'anim' + (data.index - 1) + '.end';
                }

                // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
                data.element.attr({
                    'stroke-dashoffset': -pathLength + 'px'
                });

                // We can't use guided mode as the animations need to rely on setting begin manually
                // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
                data.element.animate(animationDefinition, false);

                // add (naive) bounce
                if (data.endAngle === 360) {
                    var index = data.index;
                    var dur = 1000 * data.value / data.totalDataSum / 2;
                    var from = 0;
                    var to = -pathLength / 3;

                    for (var i = 0; i < 4; i++) {
                        data.element.animate({
                            'stroke-dashoffset': {
                                id: 'anim' + (index + 1),
                                dur: dur,
                                from: from + 'px',
                                to: to + 'px',
                                fill: 'freeze',
                                begin: 'anim' + index + '.end'
                            }
                        }, false);

                        index++;
                        dur /= 1.75;

                        var t = from;
                        from = to;
                        to = t / 2.5;
                    }
                }
            }
        });
        return chart;
    }

	ui.ready(
        scope,
		function($results){
            var $containers = $results.find(scope+"__ht-container");
            $containers.each(
                function(){
                    var hot = new Handsontable(this, $(this).data("ht"));
                    $(this).data("htinstance",hot);
                }
            );
            $results.find(scope+"__download-table").click(
                function(ev){
                    ev.preventDefault();
                    var $button = $(this);
                    var $table = $(this).closest(scope+"__table");
                    var title = $table.data("title");
                    if(!title){
                        title = "Report";
                    }

                    $button.addClass("is-loading");
                    gvf.endpoint(
                        "cp\\statReportDetailController::getReportCsv",
                        [$results.closest(".js-form").getValues(),title,$button.data("index")],
                        function(response){
                            $button.removeClass("is-loading");
                            if(response.status=="ok"){
                                window.location = response.url;
                            }else{
                                utils.alertEndpointResponse(response);
                            }
                        }
                    );
                }
            );
            var $chartContainers = $results.find(scope+"__chart-container");
            $chartContainers.each(
                function(){
                    var data = $(this).data("data");
                    var options = $(this).data("options");
                    var chart = null;
                    switch($(this).data("type")){
                        case "pie":
                            chart = createPieChart(this,data,options);
                            break;
                        case "bars":
                            chart = createBarChart(this,data,options);
                            break;
                        case "lines":
                            chart = createLineChart(this,data,options);
                            break;
                    }
                    if(chart){
                        $(this).data("chartinstance",chart);
                    }
                }
            );
		}
	);
}();

