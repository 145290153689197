//form fields
ui.formFieldModelSelector = function(){
	var scope = ".js-form-field-model-selector";

	ui.ready(
		scope,
		function($field){
			var $input = $field.find(scope+"__input");
            var $info = $field.find(scope+"__info");
            var $value = $field.find(scope+"__value");
            var $modal = $field.find(scope+"__modal").clone().appendTo($("body"));
            var $suggest = $field.find(scope+"__suggest");
            var $modalBody = $modal.find(scope+"__modal-body");

            function setValue(value,noEvent){
                if($field.data("multiple") && $.isArray(value)){
                    value = JSON.stringify(value);
                }
                $value.val(value);
                if($field.data("multiple")){
                    value = value?JSON.parse(value):[];
                }
                $field.toggleClass("has-value",$value.val()?true:false);
                $field.toggleClass("has-no-value",$value.val()?false:true);
                $modal.modal("hide");
                gvf.endpoint(
                    $field.data("endpoint")+"ModelSelectorInfo",
                    [$field.data("model"),value,$field.data("multiple"),$field.hasClass("is-editable")],
                    function(resp){
                        if(resp.status=="ok"){
                            $info.html(resp.html);
                            $info.find(scope+"__item-remove").click(
                                function(){
                                    var $item = $(this).closest(scope+"__item");
                                    removeValue($item.data("id"));
                                    $item.css("opacity",0.5);
                                }
                            );
                            if($field.data("multiple") && $field.data("sortable")){
                                var sortable = new Sortable(
                                    $info.find(scope+"__items").get(0),
                                    {
                                        onEnd: function (ev) {
                                            var fieldValue = $field.formFieldVal();
                                            if($.isArray(fieldValue)){
                                                var value = fieldValue.splice(ev.oldIndex,1)[0];
                                                fieldValue.splice(ev.newIndex,0,value);
                                                $field.formFieldSetVal(fieldValue);
                                            }
                                        }
                                    }
                                );
                            }
                        }
                    }
                );
                if(!noEvent)
                    $field.trigger("formField:changeVal");
            }

            //store function reference to load from outside
            $modal.data("setValue",setValue);
            $modal.data("addValue",addValue);

            function addValue(id,noEvent){
                if($field.data("multiple")){
                    var newIds = id;
                    if(!$.isArray(id)){
                        newIds = [id];
                    }

                    var ids = [];
                    if($value.val())
                        ids = JSON.parse($value.val());

                    for(var i in newIds){
                        if(ids.indexOf(newIds[i])==-1)
                            ids.push(newIds[i]);
                    }
                    setValue(JSON.stringify(ids),noEvent);
                }else
                    setValue(id,noEvent);
            }

            function removeValue(id,noEvent){
                if($field.data("multiple") && id!==undefined){
                    id = id.toString();
                    var ids = [];
                    if($value.val())
                        ids = JSON.parse($value.val());
                    var index = ids.indexOf(id);
                    if(index>-1)
                        ids.splice(index,1);
                    setValue(ids.length>0?JSON.stringify(ids):null,noEvent);
                }
            }

            function openModal(search,disableLoading){
                $modal.modal("show");
                if(!disableLoading)
                    $modal.addClass("is-loading");
                var fieldData = $field.data();
                fieldData.form = $field.closest(".js-form").getValues();
                fieldData.parentForm = $("body").find(".js-detail-page__main-form").getValues();
                //attach field data to modal
                $modal.data("fieldData",fieldData);
                $modal.data("refreshModal",function(){
                    openModal(search,true);
                });

                if($field.data("hasdependencies")){
                    if($field.data("hasmultipledependencies")) {
                        var $rawValues = [];
                        $field.data("multiplebind").forEach(
                            function callback(currentValue, index, array) {
                                // Iterate dependecies for grant values
                                var valueDependencieSearch = "";
                                var dependeciestr = (currentValue.stringsearch) !== "" ? currentValue.stringsearch : currentValue.dependencie;
                                $rawValues[dependeciestr] = $field.closest(".js-form").find(".js-form-field[data-name='" + currentValue.dependencie + "']").formFieldVal();
                            }
                        );
                        $field.data("rawvaluedependencie", Object.assign({},$rawValues));
                    }else{
                        var valueDependencieSearch = "";
                        var $relatedField = $($field.closest(".js-form").find(".js-form-field[data-name='"+$field.data("dependencie")+"']"));

                        if ($field.data("stringsearch") !== 'undefined' && $field.data("stringsearch") !== '') {
                            if($field.data("ismultiplevalue") !== 'undefined' && $field.data("ismultiplevalue") !== ''){
                                var str = $field.closest(".js-form").find("js-form-field[data-name='" + $field.data("dependencie") + "']").formFieldVal().replace('[','(').replace(']',')');
                                if(str !== ''){
                                    valueDependencieSearch = $field.data("stringsearch") + " IN "+ str;
                                }else{
                                    valueDependencieSearch = "1 = 0";
                                }
                            }else{
                                valueDependencieSearch = $field.data("stringsearch") + " = '" + $relatedField.formFieldVal() + "'";
                            }
                        } else {
                            valueDependencieSearch = $field.data("dependencie") + " = '" + $relatedField.formFieldVal() + "'";
                        }
                        $field.data("valuedependencie", valueDependencieSearch);
                        $field.data("rawvaluedependencie", $relatedField.formFieldVal());
                    }
                }

                $modal.data("originfielddata",$field.data());

                gvf.endpoint(
                    $field.data("endpoint")+"ListContent",
                    [$field.data("model"),search,0,null,$field.data(),"model-selector"],
                    function(resp){
                        $modal.removeClass("is-loading");
                        if(resp.status=="ok"){
                            $modalBody.html(resp.html);
                            $modalBody.find('a[href^="#"]').click(
                                function(ev){
                                    ev.preventDefault();
                                    $modal.modal("hide");
                                    addValue($(this).attr("href").substring(1));
                                }
                            );
                            $modalBody.find('.js-search-form').submit(
                                function(ev){
                                    ev.preventDefault();
                                    openModal($(this).getValues(),true);
                                }
                            );
                            ui.init($modalBody);
                        }
                    }
                );
            }

            $field.data(
                "funcGetVal",
                function(){
                    if($value.val())
                        return JSON.parse($value.val());
                    else
                        return null;
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    setValue(val);
                }
            );

            $field.find(scope+"__empty").click(
                function(ev){
                    ev.preventDefault();
                    $input.val("");
                    setValue("");
                }
            );

            $field.find(scope+"__search").click(
                function(ev){
                    ev.preventDefault();
                    openModal();
                }
            );

            $input.keyup(
                function(ev){
                    gvf.delay(
                        500,
                        function(){
                            gvf.endpoint(
                                $field.data("endpoint")+"ListContent",
                                [$field.data("model"),{q:$input.val()},0,"tableContent",$field.data(),"model-selector-quick-search"],
                                function(resp){
                                    if(resp.status=="ok"){
                                        $suggest.html($(resp.html));
                                        $suggest.show();
                                        $suggest.find('a[href^="#"]').click(
                                            function(ev){
                                                ev.preventDefault();
                                                addValue($(this).attr("href").substring(1));
                                                $suggest.hide();
                                                $input.val("");
                                            }
                                        ).mousedown(
                                            function(ev){
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                            }
                                        );
                                    }
                                }
                            );
                        }
                    );
                }
            ).keydown(
                function(ev) {
                    var codeK = ev.keyCode || ev.which;
                    if (codeK == 13) { //intro
                        ev.preventDefault();
                    }
                }
            ).blur(
                function(){
                    $suggest.hide();
                }
            ).on("focus click",
                function(){
                    $suggest.show();
                }
            );
            //initial load
            if($value.val() && $info.html()==""){
                $field.on('initialLoad',function(){
                    setValue($value.val(),true);
                });
                $field.trigger('initialLoad');
            }
		}
	);
}();