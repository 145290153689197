var cusOfferListPage = function(){
    var scope = ".js-cus-offer-list-page";
    ui.ready(scope,function($page){
        $page.find(scope+" a.nav-link").on("click",function(){
            // var statusId = $(this).attr('data-status');
            // gvf.endpoint("\\controller\\cp\\cusOfferListController::getOffersByStatusId",
            //     [statusId],
            //     function(response){
            //     if(response.status=="ok"){
            //         //window.location = response.url;
            //     }else{
            //         //utils.alertEndpointResponse(response);
            //     }
            // });

        });
    });
}();


