var deliveryNoteDetailPage = function(){
    var scope = ".js-delivery-note-detail-page";

    ui.ready(scope,function($page){
        var deliveryNoteId = $page.find('[name="id"]').val();

        $page.find(scope+"__send").off().click(function(ev){
            send("¿Enviar notificación?","Se notificará la recepción del albarán", "info");
        });

        $page.find(scope+"__warning").off().click(function(ev){
            send("¿Volver a enviar notificación?","Se enviará de nuevo la notificación de la rececpción del albarán", "warning");
        });

        $page.find("[data-name='sup_order_id']").on('formField:changeVal',(function(ev){
            var supOrderId = $page.find("[name='sup_order_id']").val();
            gvf.endpoint(
                "controller\\cp\\deliveryNoteDetailController::getSupplierAndProjectTask",
                [supOrderId],
                function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='supplier_id']").formFieldSetVal(resp.supplierId);
                        $page.find("[data-name='project_task_id']").formFieldSetVal(resp.projectTaskId);
                        $page.find("[data-name='sup_invoice_id']").formFieldSetVal(resp.supInvoiceId);

                    }
                }
            );
        }));


        $page.find("[data-name='project_task_id']").on('formField:changeVal',(function(ev){
            var projecTaskId = $page.find("[name='project_task_id']").val();
            gvf.endpoint(
                "controller\\cp\\deliveryNoteDetailController::getCusOffers",
                [projecTaskId,deliveryNoteId],
                function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='cus_offer_ids']").formFieldSetVal(resp.cusOfferIds);

                    }
                }
            );
        }));

        function send(title, text, type){
            swal({
                title: title,
                text: text,
                type:type,
                showCancelButton:true,
                confirmButtonColor:"#00bb00",
                confirmButtonText:"Aceptar",
                cancelButtonText:"Cancelar",
                closeOnConfirm:false,
                closeOnCancel:true,
                showLoaderOnConfirm:true
            },function(isConfirm){
                if(isConfirm){
                    gvf.endpoint(
                        "controller\\cp\\deliveryNoteDetailController::deliveryNoteSend",
                        [deliveryNoteId],
                        function(resp){
                            if(resp.status=="ok"){
                                window.location.reload();

                            }else{
                                utils.alertEndpointResponse(resp);
                        }
                    });
                }
            });
        }

    });
}();


