//form fields
ui.formFieldInput = function(){
	var scope = ".js-form-field-textarea";
	var tt;

	ui.ready(
		scope,
		function($field){
		    var $textarea = $field.find("textarea");
            $field.data(
                "funcGetVal",
                function(){
                    return $textarea.val();
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    $textarea.val(val);
                    $field.trigger("formField:changeVal");
                }
            );

            $textarea.keyup(
                function(ev){
                    tt = gvf.delay(
                        500,
                        function(){
                            $field.trigger("formField:changeVal");
                        }
                    );
                }
            ).blur(
                function(){
                    if(tt){
                        clearTimeout(tt);
                        tt = null;
                    }
                    $field.trigger("formField:checkRestrictions",{"input":$textarea});
                }
            ).on(
                "change",
                function(){
                    $field.trigger("formField:changeVal");
                }
            );
		}
	);
}();