var detailPage = function(){
	var scope = ".js-detail-page";

	function modelToCamelCase(model){
	    return model.charAt(0).toLowerCase()+model.slice(1)
    }

    function createModal(model,objectId,editMode,callback){
        gvf.endpoint(
            "controller\\cp\\"+modelToCamelCase(model)+"DetailController::renderDetailModal",
            [model],
            function(resp){
                if(resp.status=="ok"){
                    var $modal = $(resp.html);
                    $("body").append($modal);
                    callback($modal);
                }else{
                    utils.alertEndpointResponse(resp);
                }
            }
        );
    }

    function getDetailHtml(model,objectId,editMode,lang,context,data,callback,callbackError){
        if(callback){
            gvf.endpoint(
                "controller\\cp\\"+modelToCamelCase(model)+"DetailController::renderDetailContent",
                [model,objectId,editMode,lang,context,data],
                function(resp){
                    if(resp.status=="ok"){
                        callback(resp.html);
                    }else
                        callbackError(resp);
                }
            );
        }else{
            throw "Callback is required";
        }
    }

    function openModal(model,objectId,editMode,lang,saveCallback,data){
        createModal(
            model,
            objectId,
            editMode,
            function($modal){
                $modal.modal("show");
                // Remove modal content on close
                $modal.on('hide.bs.modal', function(){
                    $(this).html("");
                });
                if(data.title)
                    $modal.find(".js-detail-modal__title").text(data.title);
                if(data.classMin){
                    $modal.find(".modal-xl").removeClass("modal-xl");
                }
                $modal.addClass("is-loading");
                getDetailHtml(
                    model,
                    objectId,
                    editMode,
                    lang,
                    data.context ? data.context : "modal",
                    data,
                    function(html){
                        $modal.removeClass("is-loading");
                        var $modalContent = $modal.find(".js-detail-modal__body");
                        var $detailPage = $(html);
                        var fCallback = function(){
                            $modal.modal("hide");
                            saveCallback();
                        };
                        $detailPage.data("saveCallback",fCallback);
                        $modalContent.html($detailPage);

                        $modal.find(".js-detail-modal__body").unbind().on("hide",function(ev){
                            ev.preventDefault();
                            $(this).data('bs.modal', null);
                            $(this).data('modal', null);
                        });

                        ui.init($modalContent);
                        if(data.initContent){
                            var $data = {
                                additionalData: data.additionalData
                            };
                            data.initContent($detailPage,$data);
                        }
                    },
                    function(response){
                        utils.alertEndpointResponse(response);
                    }
                );
            }
        );
    }

	ui.ready(
        scope,
		function($page){
            var $detailModal = $page.closest(".js-detail-modal")
            $page.find(scope+"__main-form").submit(
            	function(ev){
            	    var $form = $(this);
            		ev.preventDefault();
            		var isOk = $form.checkRestrictions();
                    if(isOk){
                        page.showLoading();
                        gvf.endpoint(
                            $page.data("controller")+"::submitDetailForm",
                            [$page.data("table-name"),$form.getValues()],
                            function(resp){
                                page.hideLoading();
                                if(resp.status=="ok"){
                                    if($page.data("saveCallback")){
                                        $page.data("saveCallback")(resp.id);
                                    }else{
                                        window.location = resp.url;
                                    }
                                }else if(resp.validation) {
                                    $form.applyValidationResponse(resp.validation);
                                }else{
                                    utils.alertEndpointResponse(resp);
                                }
                            }
                        );
                    }
				}
			);

            $page.find(scope+"__delete").click(
                function(ev){
                    ev.preventDefault();
                    var objectId = $page.find("[name=id]").val();
                    if(!objectId)
                    	return;
                    swal({
                        title: "¿Estás seguro?",
                        text: "Se eliminará este elemento de forma permanente",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Sí, eliminar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: $page.data("deleteCallback")?true:false,
                        closeOnCancel: true
                    }, function(isConfirm){
                        if(isConfirm){
                            gvf.endpoint(
                                $page.data("controller")+"::deleteObject",
                                [$page.data("table-name"),objectId],
                                function(resp){
                                    if(resp.status=="ok"){
                                        if($page.data("deleteCallback")){
                                            $page.data("deleteCallback")();
                                        }else{
                                            window.location = resp.url;
                                        }
                                    }else
                                        utils.alertEndpointResponse(resp);
                                }
                            );
                        }
                    });
                }
            );

            $page.find(scope+"__save").click(
                function(ev){
                    $page.find(scope+"__main-form").submit();
                }
            );

            $page.find(scope+"__save-new").click(
                function(ev){
                    $page.find("[name=id]").val("");
                    $page.find(scope+"__main-form").submit();
                }
            );

            $page.find(scope+"__view,"+scope+"__edit").click(
                function(ev){
                    if($detailModal.length>0){
                        var objectId = $page.find("[name=id]").val();
                        if(!objectId)
                            return;
                        ev.preventDefault();
                        $detailModal.modal("hide");
                        openModal($page.data("model-name"),objectId,$(this).is(scope+"__edit"),null,$page.data("saveCallback"),$page.data("modalData"),$page.data("deleteCallback"));
                    }
                }
            );
		}
	);

    ui.ready(
        "a[href*='#modal-']",
        function($link){
            $link.click(
                function(ev){
                    ev.preventDefault();
                    var parts = $(this).attr("href").split("#modal-");
                    var params = gvf.parseUrlParams(parts[0].split("?")[1]);
                    var model = parts[1];
                    openModal(model.replace(/-/g,""),params.id,params.edit==1,params.lang);
                }
            );
        }
    );

	return {
        getDetailHtml:getDetailHtml,
        openModal:openModal
    };
}();

