var cusOfferQualityControlDetailPage = function(){
    var scope = ".js-cus-offer-quality-control-detail-page";

    function initCusOfferQualityControlDetailPage($modal,$data){
        $modal.find(scope+"__button").unbind().click(
            function(ev){
                var $form = $modal.find(".js-form");
                ev.preventDefault();
                var isOk = $form.checkRestrictions();
                if(isOk){
                    page.showLoading();
                    gvf.endpoint(
                        "controller\\cp\\cusOfferQualityControlDetailController::finishWork",
                        [$form.getValues(),$data],
                        function(resp){
                            page.hideLoading();
                            if(resp.status=="ok"){
                                window.location = resp.url;
                            }else if(resp.validation) {
                                $form.applyValidationResponse(resp.validation);
                            }else{
                                utils.alertEndpointResponse(resp);
                            }
                        }
                    );
                }
            }
        );
        $modal.find("[data-name='spoke_accept']").on('formField:changeVal',(function(ev){
            toggleAcceptReport();
        }));
        function toggleAcceptReport(){
            var selectValue = $modal.find("[name='spoke_accept']").children("option:selected").attr("value");
            if(selectValue == 0){
                $modal.find("[data-name='accept_report']").show("fast");
            } else{
                $modal.find("[data-name='accept_report']").hide("fast");
            }
        }
        $modal.find(scope+"__approval_button").unbind().click(
            function(ev){
                var $form = $modal.find(".js-form");
                ev.preventDefault();
                var isOk = $form.checkRestrictions();
                if(isOk){
                    page.showLoading();
                    gvf.endpoint(
                        "controller\\cp\\cusOfferQualityControlDetailController::approvalWork",
                        [$form.getValues(),$data],
                        function(resp){
                            page.hideLoading();
                            if(resp.status=="ok"){
                                window.location = resp.url;
                            }else if(resp.validation) {
                                $form.applyValidationResponse(resp.validation);
                            }else{
                                utils.alertEndpointResponse(resp);
                            }
                        }
                    );
                }
            }
        );

        $modal.find("[data-name='execution_cp_user_id']").on(
            'formField:changeVal',
            (function(ev){
                ev.preventDefault();
                executionCpUserId = $(this).closest(".js-form").find(".js-form-field[data-name=execution_cp_user_id]").formFieldVal();

                gvf.endpoint(
                    "controller\\cp\\cusOfferQualityControlDetailController::getDepartmentCpUser",
                    [executionCpUserId],
                    function(resp){
                        if(resp.status=="ok"){
                            $modal.find(".js-form-field[data-name=cp_user_department_id]").formFieldSetVal(resp.department_id);
                        }
                    }
                );
            })
        );

        $modal.find("[data-name='project']").on(
            'formField:changeVal',
            (function(ev){
                ev.preventDefault();
                $modal.find("[data-name='task']").formFieldSetVal();
                var projectId = $modal.find("[data-name='project']").formFieldVal();

                gvf.endpoint(
                    "controller\\cp\\cusOfferQualityControlDetailController::getProjectName",
                    [projectId],
                    function(resp){
                        if(resp.status=="ok"){
                            $modal.find(".js-form-field").attr("data-projectname",resp.project);
                        }
                    }
                );
            })
        );
    }

    return{
        initCusOfferQualityControlDetailPage:initCusOfferQualityControlDetailPage
    }
}();
