//form fields
ui.customObjectInfo = function(){
	var scope = ".js-custom-object-info";

	ui.ready(
		scope,
		function($info){
		    var $field = $info.closest(".js-form-field-custom-object");
            $info.find(scope+"__edit").click(
                function(ev){
                    ev.preventDefault();
                    var $item = $(this).closest(scope+"__item");
                    var data = $item.data("data");
                    var index = $item.prevAll(scope+"__item").length;
                    $field.openModal(data,index);
                }
            );
            $info.find(scope+"__edit-ref").click(
                function(ev){
                    ev.preventDefault();
                    var $item = $(this).closest(scope+"__item");
                    var data = $item.data("data");
                    detailPage.openModal(
                        "WorkerLibraryItem",
                        data.ref.id,
                        true,
                        null,
                        function(){
                            $field.formFieldSetVal($field.formFieldVal());
                        }
                    );
                }
            );
            $info.find(scope+"__copy").click(
                function(ev){
                    ev.preventDefault();
                    var $item = $(this).closest(scope+"__item");
                    var data = $item.data("data");
                    var value = $field.find("[type='hidden']").val();
                    gvf.endpoint(
                        $field.data("controller")+"::parseCopiedData",
                        [data,$field.data(),value],
                        function(resp){
                            if(resp.status == "ok"){
                                data = resp.data;
                            }
                            $field.openModal(data);
                        }
                    );
                }
            );
            $info.find(scope+"__remove").click(
                function(ev){
                    ev.preventDefault();
                    var $item = $(this).closest(scope+"__item");
                    var index = $item.prevAll(scope+"__item").length;
                    $field.removeItem(index);
                    $item.remove();
                }
            );

            if($field.data("sortable")){
                var sortable = new Sortable(
                    $info.get(0),
                    {
                        onEnd: function (ev) {
                            var fieldValue = $field.formFieldVal();
                            if($.isArray(fieldValue)){
                                var value = fieldValue.splice(ev.oldIndex,1)[0];
                                fieldValue.splice(ev.newIndex,0,value);
                                $field.formFieldSetVal(fieldValue);
                            }
                        }
                    }
                );
            }
		}
	);
}();