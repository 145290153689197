var cpMailTemplateDetailPage = function(){
	var scope = ".js-mail-template-detail-page";

	function updatePreview($page,$form,$preview){
        gvf.endpoint(
            $page.data("controller")+"::getPreviewUrl",
            [$form.getValues()],
            function(response){
                if(response.status=="ok"){
                    $preview.find("iframe").attr("src",response.url);
                }
            }
        );
    } 

	ui.ready(
        scope,
		function($page){
            var $form = $page.find(".js-detail-page__main-form");
            var $preview = $form.find(scope+"__preview");
            $form.on(
                "formField:changeVal",
                function(ev){
                    updatePreview($page,$form,$preview);
                }
            );
            $preview.html($("<iframe />").attr("src",$preview.data("src")));

            $page.find(scope+"__preview-open").click(
                function(ev){
                    ev.preventDefault();
                    window.open($preview.find("iframe").attr("src"),"_blank");
                }
            );

            $page.find(scope+"__layout-change").click(
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                        "cp\\mailTemplateDetailController::setLayout",
                        [$form.find("[name=id]").val(),$(this).data("id")],
                        function(resp){
                            if(resp.status=="ok"){
                                window.location.reload();
                            }else{
                                utils.alertEndpointResponse(resp);
                            }
                        }
                    );
                }
            );
		}
	);
}();

