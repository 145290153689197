var supplierTechnicalDocumentDetailPage = function(){
    var scope = ".js-supplier-technical-document-detail-page";
    ui.ready(scope,function($page){
        $page.find("[data-name='created']").on('formField:changeVal',(function(ev){
            var fileTypeId = $page.find("[data-name='supplier_technical_document_type_id']").formFieldVal();
            var created = $page.find("[data-name='created']").formFieldVal();
            if(created){
                gvf.endpoint("controller\\cp\\supplierTechnicalDocumentDetailController::getExpirationDate",[fileTypeId,created],function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='expiration_date']").formFieldSetVal(resp.expiration_date);
                        $page.find("[name='expiration_date']")[0].value = resp.expiration_date;
                    }
                })
            }
        }));
    });
}();
