var supplierTechnicalListPage = function(){
    var scope = ".js-supplier-technical-list-page";
    function getItemId($item, name){
        var url = $item.find("a").eq(0).attr("href").substring(1);
        if (!url) url = location.href;
        name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
        var regexS = "[\\?&]"+name+"=([^&#]*)";
        var regex = new RegExp( regexS );
        var results = regex.exec( url );
        return results == null ? null : results[1];
    }
    ui.ready(
        scope,
        function($page){
            $page.find(scope+"__zipDocuments").click(function(ev){
                ev.preventDefault();
                var selectedIds = [];
                $("body").find(".js-simple-list-page__tr.is-selected").each(
                    function(){
                        selectedIds.push(getItemId($(this),'id'));
                    }
                );
                if(selectedIds.length <1){
                    utils.alertEndpointResponse("Es necesario seleccionar, al menos, un técnico");
                } else {
                    var win = window.open("/tec-documento-download/?id="+selectedIds);
                    if (win) {
                        win.focus();
                    }

                }

            });
            $page.find(scope+"__validateTechnician").click(function(ev){
                var selectedIds = [];
                $("input[type='checkbox']:checked").closest(".cp-simple-list-page__tr").each(function(){
                    selectedIds.push(getItemId($(this),'id'));
                });
                swal({
                        title:"¿Confirmar validar a los técnicos?",
                        text:"Va a validar los técnicos seleccionados ¿Está seguro?",
                        type:"info",
                        showCancelButton: true,
                        confirmButtonColor: "#00bb00",
                        confirmButtonText: "Aprobar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        showLoaderOnConfirm: false},
                    function(isConfirm){
                        if(selectedIds.length >0){
                            gvf.endpoint(
                                "controller\\cp\\supplierTechnicalListController::validateTechnician",
                                [selectedIds],
                                function(resp){
                                    utils.alertEndpointResponse(resp);
                                    if(resp.status == "ok"){
                                        window.location.reload();
                                    }
                                }
                            );
                        }  else{
                            utils.alertEndpointResponse("Es necesario seleccionar, al menos, un técnico");
                        }

                    }
                )

            });

        }




    );
}();

