var supplierDocumentsListController = function(){
	var scope = ".js-supplier-sup-document-type-list-page";
	ui.ready(
        scope,
		function($page){
            $page.find(scope+"__form").submit(
                function(ev){
                    ev.preventDefault();
                    gvf.endpoint(
                    	"controller\\cp\\supplierDocumentsListController::save",
						[$(this).getValues()],
						function(resp){
                            utils.alertEndpointResponse(resp);
						}
					);
                }
            );
		}
	);
}();

