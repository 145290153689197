//form fields
ui.formField = function(){
	var scope = ".js-form-field";

    //Shows errors on field level field
    function showFieldError($field,errors){
        var $error = $field.find(scope+"__error");
        if($.isArray(errors))
            errors = errors.join("<br />");

        $field.find(scope+"__error").html("");
        if(errors){
            $field.addClass("has-error");
            $error.html(errors);
        }else{
            $field.removeClass("has-error");
        }
    }

    /**
     * Checks field restrictions
     * @param $field
     * @param contextual
     * @returns {boolean} If field has errors
     */
    function checkFieldRestrictions($field,contextual){
        var validation = $field.data("restrictions");
        if(!$.isPlainObject(validation)){
            return true;
        }
        var errField = contextual?"contextualErrorMessage":"errorMessage";
        var val = $field.formFieldVal();
        var errors = [];
        if(validation.required){
            if(!val || val.length==0){
                errors.push(validation.required[errField]);
            }
        }
		if(validation.regexp){
			if(val && val.length>0){
				var regexp = new RegExp(validation.regexp.regexp);
				if(!regexp.test(val)){
                    errors.push(validation.regexp[errField]);
                }
			}
		}
        if(validation.callback){
            if(val && val.length>0){
                var additionalParams = validation.callback.additionalParams;
                var params = [];
                if(additionalParams && additionalParams.length>0)
                    params = additionalParams.slice(); //clone array
                params.splice(0,0,val);
                var response = gvf.endpointSync(validation.callback.function,params);
                if(response.status!="ok"){
                    errors.push(response.messages?response.messages[0]:response);
                }
            }
        }
        if(validation.length){
            if(val && val.length>0){
                if(val.length>validation.length.max){
                    errors.push(validation.length[errField]);
				}
            }
        }
        if(validation.range){
            if(val && val.length>0){
                if(val<validation.range.min || val>validation.range.max){
                    errors.push(validation.range[errField]);
                }
            }
        }
        if(validation.minvalue){
            if(val && val.length>0){
                if(parseFloat(val)<parseFloat(validation.minvalue.min)){
                    errors.push(validation.minvalue[errField]);
                }
            }
        }
        if(validation.maxvalue){
            if(val && val.length>0){
                if(parseFloat(val)>parseFloat(validation.maxvalue.max)){
                    errors.push(validation.maxvalue[errField]);
                }
            }
        }
        showFieldError($field,errors);
		return (errors.length==0);
    }

    ui.ready(
    	scope,
		function($field){
            $field.on(
            	"formField:checkRestrictions",
				function(ev){
            		checkFieldRestrictions($(this),true);
				}
			);

            $field.find(":input").on(
                "invalid",
                function(ev){
                    ev.preventDefault();
                    var restrictionsPassed = checkFieldRestrictions($field,true);
                    if (!ev.target.validity.valid && restrictionsPassed) {
                        showFieldError($field,ev.target.validationMessage);
                    }
                }
            );

            //initialize bootstrap tooltips
            $field.find('[data-toggle="tooltip"]').tooltip();
        }
	);

	//extend jquery to get values
	jQuery.fn.extend(
		{
			"formFieldVal":function(){
				if(this.is(scope)){
                    if(this.data("funcGetVal")) //if field has get value function
                        return this.data("funcGetVal")();
				}
				return undefined;
			},
            "formFieldSetVal":function(val){
                if(this.is(scope)){
                    if(this.data("funcSetVal")) //if field has get value function
                        return this.data("funcSetVal")(val);
                }
            },
            "formFieldCheckRestrictions":function(val){
                if(this.is(scope)){
                    return checkFieldRestrictions(this);
                }
                return false;
            },
            "formFieldShowError":function(errors){
                if(this.is(scope)){
                    showFieldError(this,errors);
                }
            }
		}
	);
}();