var workerLibraryItemListPage = function(){
	var scope = ".js-worker-library-item-list-page";

    ui.ready(
        scope,
		function($page){
            $page.find(scope+"__add-ref,"+scope+"__add-value").click(
                function(ev){
                    var $item = $(this).closest(".js-simple-list-page__tr");
                    ev.preventDefault();
                    ev.stopPropagation();
                    gvf.endpoint(
                        "controller\\cp\\workerLibraryItemListController::getLibraryItemValue",
                        [$item.data("id"),$(this).is(scope+"__add-ref")],
                        function(response){
                            if(response.status=="ok"){
                                if(response.value){
                                    $item.trigger("workerItem:addValue",[response.value]);
                                }else{
                                    $item.trigger("workerItem:addRef",[response.id]);
                                }
                            }else
                                utils.alertEndpointResponse(response);
                        }
                    );
                }
            );
		}
	);
}();

