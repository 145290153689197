//rich editor field
ui.formFieldRichEditor = function(){
	var scope = ".js-form-field-rich-editor";

    function createTinyMCEOptions($field,$textarea){
        var opc =
            {
                "selector":"#"+$textarea.attr("id"),
                "body_class": "u-gvml",
                "statusbar" : false,
                "menubar" : false,
                "language": "es",
                "language_url" : '/nodemdls/tinymce-i18n/langs/es.js',
                "content_css": "/css/web.min.css",
                "gecko_spellcheck": true,
                "plugins": "print,paste,searchreplace,advlist,charmap,lists,table",
                "entity_encoding" : "named",
                "convert_urls" : false,
                "force_p_newlines" : true,
                "theme":"modern",
                "toolbar": "",

                "setup" : function(ed) {
                    ed.addButton('anchor',anchorButton(ed));
                    ed.addButton('link',linkButton(ed,$field));
                    ed.addButton('title',titleButton(ed,"",$field));
                    ed.addButton('subtitle',titleButton(ed,"sub",$field));
                    ed.addButton('newLine',newLineButton(ed));
                    ed.addButton('image',imageButton(ed,$field));
                    ed.addButton('icon',iconButton(ed,$field));
                    ed.addButton('video',videoButton(ed,$field));
                    ed.on(
                        'change',
                        function(){
                            tinymce.triggerSave();
                        }
                    );
                }
            };

        opc.toolbar = "bold underline italic icono | link unlink anchor | undo redo search replace pastetext | subscript superscript | charmap ";
        opc.valid_elements = "a[href|title],span[style|class],strong,em,sub,sup,br,img[src|class|width|height|title]";

        if($field.data("editor-type")=="content"){
            opc.toolbar += " | title subtitle bullist numlist newLine | image video";
            opc.valid_elements += ",p,ul,ol,li,h1[class],h2[class],h3[class],h4[class],h5[class]";
        }else if($field.data("editor-type")=="email"){
            opc.toolbar += " | title subtitle";
            opc.valid_elements += ",p,h1[class],h2[class],h3[class],h4[class],h5[class]";
        }else{
            opc.force_p_newlines = false;
            opc.forced_root_block = '';
        }
        return opc;
    }

    function newLineButton(ed){
        return {
            title : 'Insertar salto de línea',
            onclick : function(){
                ed.selection.setContent(ed.selection.getContent({format : 'raw'})+"<br />");
            }
        };
    }

    function anchorButton(ed){
        return {
            title : 'Insertar ancla',
            onclick : function(){
                swal({
                    title: "Insertar ancla",
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: true,
                    inputPlaceholder: "Nombre del ancla",
                    inputValue: "Check"
                }, function (inputValue) {
                    if (inputValue === false) return false;
                    if (inputValue === "") {
                        swal.showInputError("El nombre no puede estar vacío");
                        return false
                    }
                    ed.selection.setContent("<img class=\"ancla ancla"+inputValue+"\" title=\"#"+inputValue+"\" src=\"/img/cp/editor/anchor.gif\" />");
                    return true;
                });
            }
        };
    }

    function linkButton(ed,$field){
        return {
            title : 'Enlazar el texto seleccionado',
            onclick : function(){
                var $modal = $field.find(scope+"__link-modal").clone().appendTo($("body"));
                ui.init($modal);
                var $sel = $(ed.selection.getNode());
                if($sel.attr("href")){
                    $modal.find(".js-form-field[data-name=link]").formFieldSetVal(atob($sel.attr("href")));
                }else{
                    $sel = null;
                }
                $modal.modal("show");
                $modal.find(scope+"__save-link").click(
                    function(ev){
                        $modal.modal("hide");
                        gvf.endpoint(
                            "\\controller\\web\\webController::getLinkDescription",
                            [$modal.find("[name=link]").val()],
                            function(resp){
                                if(resp.status=="ok"){
                                    if($sel){
                                        $sel.attr("href",resp.base64);
                                        $sel.attr("title",resp.description);
                                    }else{
                                        ed.selection.setContent("<a href=\""+resp.base64+"\" title=\""+resp.description+"\">"+ed.selection.getContent({format : 'raw'})+"</a>");
                                    }
                                    ed.save();
                                }
                            }
                        );
                    }
                );
            }
        };
    }

    function insertMultimediaElement(ed,$field,fileTypeId,alignment){
        listPage.selectModelItem(
            "RepFile",
            function(id){
                var fInsertElement = function(align){
                    if(fileTypeId==2){ //video
                        var size = "";
                        if(align!="full"){
                            size = " width='280' height='192'";
                        }
                        var img = "<img src=\"/img/cp/editor/video-placeholder.jpg\" class=\"vid vid"+id+" "+align+"\""+size+" />";
                    }else{
                        gvf.endpoint(
                            "cp\\repFileVersionController::getImageUrlByAlignment",
                            [id,align],
                            function(resp){
                                if(resp.status=="ok"){
                                    var img = "<img src=\""+resp.url+"\" class=\"img img"+id+" "+align+"\" />";
                                    ed.selection.setContent(img);
                                    ed.save();
                                }
                            }
                        );
                    }
                };

                if(!alignment){
                    var $modal = $field.find(scope+"__align-modal").clone().appendTo($("body"));
                    $modal.modal("show");
                    $modal.find(scope+"__align-modal-link").click(
                        function(ev){
                            $modal.modal("hide");
                            fInsertElement($(this).data("value"));
                        }
                    );
                }else
                    fInsertElement(alignment);
            }
        );
    }

    function imageButton(ed,$field,alignment){
        return {
            title : 'Insertar una imagen',
            onclick : function(){
                insertMultimediaElement(ed,$field,1,alignment);
            }
        };
    }

    function iconButton(ed,$field){
        return {
            title : 'Insertar un icono',
            onclick : function(){
                listPage.selectModelItem(
                    "RepFile",
                    function(id){
                        gvf.endpoint(
                            "cp\\repFileVersionController::getImageUrl",
                            [id,"png",32,32,1],
                            function(resp){
                                if(resp.status=="ok"){
                                    var img = "<img src=\""+resp.url+"\" class=\"img img"+id+" icon\" />";
                                    ed.selection.setContent(img);
                                    ed.save();
                                }
                            }
                        );
                    },
                    null,
                    {"t":1}
                );
            }
        };
    }

    function videoButton(ed,$field,alignment){
        return {
            title : 'Insertar un video',
            onclick : function(){
                insertMultimediaElement(ed,$field,2,alignment);
            }
        };
    }

    function titleButton(ed,sub,$field){
        return {
            title : 'Insertar un '+sub+'título',
            onclick : function() {
                var $modal = $field.find(scope+"__title-modal").clone().appendTo($("body"));
                var $sel = $(ed.selection.getNode());
                if(!$sel.is("h1,h2,h3,h4,h5")){
                    $sel = null;
                }
                $modal.modal("show");
                $modal.find(scope+"__title-modal-link").click(
                    function(ev){
                        $modal.modal("hide");
                        var selectedLevel = $(this).data("level");
                        var txt = ed.selection.getContent({format : 'text'});
                        if(txt.length==0)
                            txt = "Texto de título";
                        if($sel){
                            txt = $sel.text();
                        }
                        var html = "<h"+selectedLevel+" class=\"u-gvml__"+sub+"title\">"+txt+"</h"+selectedLevel+">";
                        if($sel){
                            $sel.replaceWith(html);
                        }else{
                            ed.selection.setContent(html);
                        }
                        ed.save();
                    }
                );
            }
        };
    }

	ui.ready(
		scope,
		function($field){
		    var $input = $field.find("textarea");
		    $field.data(
                "funcGetVal",
                function(){
                    return tinymce.get($input.attr("id")).getContent({format : 'raw'});
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    tinymce.get($input.attr("id")).setContent(val);
                    $field.trigger("formField:changeVal");
                }
            );

            if($input.attr("rows")>0)
                $input.css("height",$input.attr("rows")+"em");

            gvf.loadJsScript(
                "/nodemdls/tinymce/tinymce.min.js",
                function(){
                    setTimeout(
                        function(){
                            tinymce.baseURL = "/nodemdls/tinymce";
                            tinymce.init(createTinyMCEOptions($field,$input));
                        },
                        500
                    );
                }
            );
		}
	);
}();