var reportDashboardWorkerForm = function(){
	var scope = ".js-report-dashboard-worker-form";

	ui.ready(
		scope,
		function($form){
            var $anchorField = $form.find("[name=anchor]");
            var $tablesField = $form.find("[data-name=tables]")
            var $reportResults = null;

            function refreshReportResults(){
                if(!$reportResults){
                    var $container = $("<div />").addClass("form-group").insertAfter($tablesField);
                    $reportResults = $("<div />").css({"overflow":"auto","max-height":"300px","margin-right":"20px","border":"1px solid #ddd","padding":"0 5px"});
                    $container.append("<label class='control-label'>Selecciona el contenido a mostrar</label>");
                    $container.append($reportResults);
                }

                if($tablesField.formFieldVal()){
                    $reportResults.html("Cargando informe...");
                    gvf.endpoint(
                        "cp\\statReportDetailController::getReport",
                        [{"content.tables":$tablesField.formFieldVal()}],
                        function(resp){
                            if(resp.status=="ok"){
                                $reportResults.html(resp.html);
                                ui.init($reportResults);

                                $reportResults.find("[data-dashboard-anchor]").addClass("is-selectable");

                                $reportResults.find(".is-selectable").hover(
                                    function(){
                                        $(this).addClass("is-selected");
                                    },
                                    function(){
                                        $(this).removeClass("is-selected");
                                    }
                                ).click(
                                    function(ev){
                                        ev.stopPropagation();
                                        ev.preventDefault();
                                        $reportResults.find(".is-selected-current").removeClass("is-selected-current");
                                        $(this).addClass("is-selected is-selected-current");
                                        $anchorField.val($(this).data("dashboard-anchor"));
                                    }
                                ).children().css("pointer-events","none");

                                //initial load
                                var initialAnchor = $anchorField.val();
                                if(initialAnchor){
                                    $reportResults.find("[data-dashboard-anchor="+initialAnchor+"]").addClass("is-selected is-selected-current");
                                }
                            }
                        }
                    );
                }else{
                    $reportResults.html("");
                }
            }

            $tablesField.on(
                "formField:changeVal",
                function(ev){
                    //reset extract field
                    $anchorField.val("");
                    refreshReportResults();
                }
            );

            refreshReportResults();
		}
	);
}();