var utils = function(){
    /**
     * Creates an alert based on an endpoint response
     * @param {*} response
     */
    function alertEndpointResponse(response){
        var type = "error";
        if(response.status=="ok")
            type = "success";

        var message = "";
        if(response.messages)
            message = response.messages.join("<br />");
        else if(response.message)
            message = response.message;
        else if(response)
            message = response;

        if(message.length<300){
            swal(
                {
                    type: type,
                    title: "",
                    text: message
                }
            );
        }else{
            alert(message);
        }
    }

    return {
        "alertEndpointResponse":alertEndpointResponse
    };
}();