var customerDetailPage = function(){
    var scope = ".js-customer-detail-page";
    ui.ready(scope,function($page){
        // $page.find("[data-name='city']").off().on('formField:changeVal',function(){
        //     var cityId = $page.find("[name='city']").val();
        //     gvf.endpoint("controller\\cp\\geoCityListController::getProvinceByCityId",[cityId],function(resp){
        //
        //         $page.find("[name='province']").val(resp.geo_province_id);
        //         $page.find(scope+"__change-province .input-group .cp-form-field-model-selector__info").text("placeholder");
        //         //$page.find("[data-name='province']").trigger('formField:changeVal');
        //     })
        //
        // });
        $page.find(scope+"__save").off("click").click(function(ev){
            $page.find(".js-form.js-detail-page__main-form")[0].checkValidity();
        });
        //$(".js-form.js-detail-page__main-form")[0].checkValidity();

        //end ready

        //tabs
        $page.find('#customer_details-tab').click(function () {
            $(this).tab('show');
        });

        $page.find('#cusFacility-tab').click(function () {
            $(this).tab('show');
        });

        $page.find('#cusOffer-tab').click(function () {
            $(this).tab('show');
        });

        $page.find('#cusProject-tab').click(function () {
            $(this).tab('show');
        });

        $page.find('#cusWarranty-tab').click(function () {
            $(this).tab('show');
        });
    });
}();