var listPage = function(){
    function createModal(model,callback){
        gvf.endpoint(
            "cp\\objectListController::renderListModal",
            [model],
            function(resp){
                if(resp.status=="ok"){
                    var $modal = $(resp.html);
                    $("body").append($modal);
                    callback($modal);
                }else{
                    utils.alertEndpointResponse(resp);
                }
            }
        );
    }

	function selectModelItem(model,selectCallback,controllerName,search,fieldData,contentReadyCallback){
        if(!controllerName){
            controllerName = model.charAt(0).toLowerCase()+model.substring(1)+"ListController";
        }

        createModal(
            model,
            function($modal){
                $modal.modal("show");
                $modal.addClass("js-form-field-model-selector__modal is-loading");

                var selectItem = function(id){
                    $modal.modal("hide");
                    selectCallback(id);
                };

                gvf.endpoint(
                    "cp\\"+controllerName+"::renderListContent",
                    [model,search,0,null,fieldData,"model-selector"],
                    function(resp){
                        $modal.removeClass("is-loading");
                        if(resp.status=="ok"){
                            var $modalBody = $modal.find(".js-list-modal__body");
                            $modalBody.html(resp.html);
                            $modal.data("addValue",selectItem);
                            $modal.data("setValue",selectItem);
                            $modalBody.find('.js-search-form').submit(
                                function(ev){
                                    ev.preventDefault();
                                    openModal($(this).getValues());
                                }
                            );
                            ui.init($modalBody);
                            if(contentReadyCallback)
                                contentReadyCallback($modal);
                        }
                    }
                );
            }
        );

    }

	return {
	    "selectModelItem":selectModelItem
    };
}();

