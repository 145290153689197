var cusOfferManualSendPage = function(){
    var scope = ".js-cus-offer-manual-send-page";

    function initCusOfferManualSend($modal,$data){
       $modal.find(scope+"__send-offer").click(
           function(ev){
               ev.preventDefault();
               var offerId = $modal.find("[name=id]").val();
               var recipients = $modal.find("[name=recipients]").val();
               gvf.endpoint(
                   "\\controller\\cp\\cusOfferManualSendDetailController::sendOffer",
                   [offerId,recipients, false, true],
                   function(resp){
                       if(resp.status = "ok"){
                           utils.alertEndpointResponse(resp);
                           window.location.reload();
                       }else{
                           utils.alertEndpointResponse(resp);

                       }
                   }
               )
           }
       )
    }

    return {
        initCusOfferManualSend: initCusOfferManualSend
    };
}(

);


