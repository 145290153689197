var requestOrderPage = function(){
	var scope = ".js-request-order-page";
	ui.ready(
        scope,
		function($page){
            $page.find(scope+"__main-form").submit(
            	function(ev){
            		ev.preventDefault();
            		page.showLoading();
					gvf.endpoint(
                        "controller\\cp\\supOrderRequestController::submitForm",
						[$(this).getValues()],
						function(resp){
                            page.hideLoading();
                            utils.alertEndpointResponse(resp);
                            if(resp.status=="ok"){
                                setTimeout(
                                    function(){
                                        window.location = resp.url;
                                    },
                                    2000
                                );
                            }
						}
					);
				}
			);
		}
	);
}();

