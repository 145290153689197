var supplierLocationDetailPage = function(){
    var scope = ".js-supplier-location-detail-page";
    ui.ready(scope,function($page){

        $page.find("[data-name='supplier_id']").on('formField:changeVal',(function(ev){
            var supplier_id = $(this).find("[name='supplier_id']").val();
            if(supplier_id){
                gvf.endpoint(
                    "controller\\cp\\supplierLocationDetailController::getSupplierLocationInfo",
                    [supplier_id],
                    function(resp){
                        if(resp.status == "ok"){
                            $page.find("[data-name='address']").formFieldSetVal(resp.data.address);
                            $page.find("[data-name='geo_city_id']").formFieldSetVal(resp.data.city);
                            $page.find("[data-name='geo_province_id']").formFieldSetVal(resp.data.province);
                            $page.find("[data-name='postal_code']").formFieldSetVal(resp.data.postal_code);
                        }else{
                            customer.formFieldSetVal("");
                        }
                    }
                );
            }
        }));
        //end ready
    });
}();


